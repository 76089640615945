<template>
  <bizfly-dialog
    class="bizfly-ui bizfly-suggestion-dialog"
    :title="title"
    :visible="visible"
    width="600px"
    @close="$emit('close')"
  >
    <bizfly-row>
      <bizfly-col :span="24" class="resources">
        <h3>Thông tin dịch vụ: {{ service.name }}</h3>
        <h4>{{ service.infomation }}:</h4>
        <bizfly-collapse v-if="service.resources.length > 0" class="info">
          <bizfly-collapse-item
            v-for="item in service.resources"
            :key="item.index"
            :class="item.items.length === 0 ? 'hide-arrow' : ''"
          >
            <template slot="title">
              <div class="title-detail pr-2">
                <bizfly-tooltip placement="top">
                  <strong slot="content">{{ item.name }}</strong>
                  <strong>{{ item.name }}</strong>
                </bizfly-tooltip>
                <b>{{ item.value }}</b>
              </div>
            </template>
            <div v-if="item.items.length > 0" class="detail">
              <ul class="ml-3 mb-2 mt-2">
                <li v-for="info in item.items" :key="info.id" class="pr-2 mb-1">
                  <div v-if="service.isResize && info.description">
                    <p>- {{ info.name }}</p>
                    <small class="pl-2">{{ info.description }}</small>
                  </div>
                  <span v-else>- {{ info.name }}</span>
                  <b>{{ info.value }}</b>
                </li>
              </ul>
            </div>
          </bizfly-collapse-item>
        </bizfly-collapse>
        <bizfly-alert v-else type="warning" :closable="false" class="my-2">{{ service.notify }}</bizfly-alert>
        <bizfly-row
          v-if="service.monthlyFee && !service.isResize && service.resources.length > 1"
          type="flex"
          justify="space-between"
          align="top"
          class="my-3 pl-1 pr-2 total"
        >
          <div>
            <h4>Tổng chi phí hàng tháng:</h4>
          </div>
          <b class="text-bold">{{ service.monthlyFee }}</b>
        </bizfly-row>
        <bizfly-row v-if="service.isResize" class="my-3 pl-1 pr-2 total">
          <bizfly-row type="flex" justify="space-between" align="top">
            <h4>Chi phí chênh lệch:</h4>
            <b class="text-bold">{{ numberWithDelimiter(service.costDifference) }} VNĐ</b>
          </bizfly-row>
        </bizfly-row>
        <bizfly-row
          type="flex"
          justify="space-between"
          align="middle"
          class="mt-2 pl-1 pr-2 text-danger"
        >
          <div class="charge-this-month">
            <h4 class="text-danger">Thanh toán hôm nay:</h4>
            <small
              v-if="today !== lastDayOfMonth"
              class="text-danger"
            >(Từ ngày {{ today }} tới {{ lastDayOfMonth }})</small>
            <small v-else class="text-danger">(đến hết ngày {{ lastDayOfMonth }})</small>
          </div>
          <b>{{ numberWithDelimiter(data.suggestion.thisMonthCharge) }} VNĐ</b>
        </bizfly-row>
        <p
          v-if="totalCharge > 0 && !whitelist && data.accountType === 'pre-paid'"
          class="text-danger mt-3 pl-1 pr-2"
        >Số dư không đủ để thực hiện thanh toán</p>
      </bizfly-col>
    </bizfly-row>
    <bizfly-row slot="footer" type="flex" justify="end" align="middle">
      <bizfly-button type="default" :disabled="loading" @click="$emit('close')">Hủy bỏ</bizfly-button>
      <bizfly-button
        v-if="!(totalCharge > 0 && !whitelist && data.accountType === 'pre-paid')"
        type="primary"
        :loading="loading"
        @click="$emit('confirm')"
      >Xác nhận</bizfly-button>
    </bizfly-row>
  </bizfly-dialog>
</template>

<script>
import { numberWithDelimiter } from '../../lib/common'

import BizflyRow from '../Row/index'
import BizflyCol from '../Col/index'
// import BizflyCheckbox from '../Checkbox'
import BizflyButton from '../Button/index'
import BizflyDialog from '../Dialog/index'
import BizflyCollapse from '../Collapse/index'
import BizflyTooltip from '../Tooltip/index'
import BizflyCollapseItem from '../CollapseItem/index'
import BizflyAlert from '../Alert/index'
export default {
  name: 'ConfirmPaymentDialog',
  components: {
    BizflyAlert,
    BizflyRow,
    BizflyCol,
    BizflyButton,
    BizflyDialog,
    BizflyCollapse,
    BizflyCollapseItem,
    BizflyTooltip
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Xác nhận mua gói cước'
    },
    visible: {
      default: true,
      type: Boolean
    },
    whitelist: {
      type: Boolean,
      required: false
    },
    data: {
      require: true,
      type: Object
    },
    service: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checked: true
    }
  },
  computed: {
    totalCharge() {
      return this.checked ? this.data.totalPayment : this.data.mandatoryPayment
    },
    today() {
      const date = new Date()
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    },
    lastDayOfMonth() {
      const date = new Date()
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      return (lastDay.getDate()) + '/' + (lastDay.getMonth() + 1) + '/' + lastDay.getFullYear()
    },
    thisMonth() {
      return new Date().getMonth() + 1
    },
    nextMonth() {
      return this.thisMonth % 12 + 1
    }
  },
  methods: {
    numberWithDelimiter
  }
}
</script>

<style lang="scss" scoped>
</style>
