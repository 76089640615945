<script>
import { TableColumn } from 'element-ui'

export default {
  name: 'BizflyTableColumn',
  extends: TableColumn,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-table-column'
    }
  }
}
</script>
