<script>
import { Button } from 'element-ui'

export default {
  name: 'BizflyButton',
  extends: Button,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-button'
    }
  }
}
</script>
