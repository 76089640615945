// THIS FILE IS AUTOMATICALLY GENERATED IN:
//
//   build-utils/update-file-index.js
//
// YOU SHOULD NEVER UPDATE THIS FILE DIRECTLY

import '@/plugins/element'
import '@/assets/stylesheets/bizfly-ui.scss'
import 'element-ui/lib/theme-chalk/display.css'

import CONSTANTS from '../config/constants'
import userServices from '../lib/user'
import projectServices from '../lib/project'

import BizflyHeader from './Header/index'
import BizflyButton from './Button/index'
import BizflyButtonGroup from './ButtonGroup/index'
import BizflyTabs from './Tabs/index'
import BizflyTabPane from './TabPane/index'
import BizflyScrollBar from './ScrollBar/index'
import BizflyTable from './Table/index'
import BizflyTableColumn from './TableColumn/index'
import BizflyMenu from './Menu/index'
import BizflyMenuItem from './MenuItem/index'
import BizflyStatus from './Status/index'
import BizflyContainer from './Container/index'
import BizflyRow from './Row/index'
import BizflyCol from './Col/index'
import BizflyTitle from './Title/index'
import BizflyLoaderPage from './LoaderPage/index'
import BizflyPagination from './Pagination/index'
import BizflyBetaRibbon from './Ribbon/index'
import BizflyAlert from './Alert/index'
import BizflyAutocomplete from './Autocomplete/index'
import BizflyCascader from './Cascader/index'
import BizflyCheckbox from './Checkbox/index'
import BizflyCheckboxGroup from './CheckboxGroup/index'
import BizflyDatePicker from './DatePicker/index'
import BizflyForm from './Form/index'
import BizflyFormItem from './FormItem/index'
import BizflyInput from './Input/index'
import BizflyInputNumber from './InputNumber/index'
import BizflyOption from './Option/index'
import BizflyOptionGroup from './OptionGroup/index'
import BizflyRadio from './Radio/index'
import BizflyRadioGroup from './RadioGroup/index'
import BizflySelect from './Select/index'
import BizflySlider from './Slider/index'
import BizflySwitch from './Switch/index'
import BizflyTimePicker from './TimePicker/index'
import BizflyTimeSelect from './TimeSelect/index'
import BizflyTransfer from './Transfer/index'
import BizflyUpload from './Upload/index'
import BizflyFaq from './Faq/index'
import BizflyDialog from './Dialog/index'
import BizflyCard from './Card/index'
import BizflyTooltip from './Tooltip/index'
import BizflySteps from './Steps/index'
import BizflyCustomSelect from './CustomSelect/index'
import BizflySuggestionDialog from './SuggestionDialog/index'
import BizflyConfirmPaymentDialog from './ConfirmPaymentDialog/index'
import BizflyUpgradeDialog from './UpgradeDialog/index'
import BizflyTag from './Tag/index'
import BizflyProgress from './Progress/index'
import BizflyDropdown from './Dropdown/index'
import BizflyPopover from './Popover/index'
import BizflyCollapse from './Collapse/index'
import BizflyCollapseItem from './CollapseItem/index'
import BizflyDropdownMenu from './DropdownMenu/index'
import BizflyDropdownItem from './DropdownItem/index'
import BizflyIcon from './Icon'
import BizflyFeedback from './Feedback'
import BizflyReleaseNotes from './ReleaseNotes'
import BizflyBreadcrumb from './Breadcrumb/index'
import BizflyBreadcrumbItem from './BreadcrumbItem/index'
import BizflySupportBox from './SupportBox/index'
import BizflyError500 from './ErrorPage/500/index'
import BizflyError502 from './ErrorPage/502/index'
import BizflyError404 from './ErrorPage/404/index'
import BizflyError403 from './ErrorPage/403/index'
import BizflyManualSslForm from './SslForm/ManualForm/index'
import BizflyAutoSslForm from './SslForm/AutoForm/index'
import BizflySelectTree from './SelectTree/index'
import BizflyBacktop from './Backtop/index'
import BizflyUserRegions from './UserRegions/index'
import BizflyNavMenu from './NavMenu/index'
import BizflyNavMenuItem from './NavMenuItem/index'
import BizflyNavMenuItemGroup from './NavMenuItemGroup/index'
import BizflySubmenu from './Submenu/index'
import BizflyTree from './Tree/index'
import BizflySupportQuotas from './SupportQuotas/index'
import BizflySupportDialog from './SupportDialog/index'
import BizflyUsingService from './UsingService/index'
import BizflySkeletonLoading from './SkeletonLoading/index'
import { Notification, Message } from 'element-ui'

async function checkUserRegion() {
  // Check last region
  if (window.location.hostname.split('.')[0] === 'local') return
  if (window.location.href.includes('hapu-backup')) return
  const currentHost = window.location.host
  const slug = window.location.href.split('/')[3]
  const subDomain = currentHost.split('.')[0]
  let currentEnvironment = 'manage'
  let currentRegion = 'hn'

  switch (subDomain) {
    case 'local':
      currentRegion = 'hn'
      currentEnvironment = 'staging'
      break
    case 'hn-local':
      currentRegion = 'hn'
      currentEnvironment = 'staging'
      break
    case 'hcm-local':
      currentRegion = 'hcm'
      currentEnvironment = 'staging'
      break
    case 'vc-hanoi-local':
      currentRegion = 'vc-hanoi'
      currentEnvironment = 'staging'
      break
    case 'vc-hcm-local':
      currentRegion = 'vc-hcm'
      currentEnvironment = 'staging'
      break
    case 'hn-staging':
      currentRegion = 'hn'
      currentEnvironment = 'staging'
      break
    case 'hcm-staging':
      currentRegion = 'hcm'
      currentEnvironment = 'staging'
      break
    case 'staging':
      currentRegion = 'hn'
      currentEnvironment = 'staging'
      break
    case 'vc-hanoi-staging':
      currentRegion = 'vc-hanoi'
      currentEnvironment = 'staging'
      break
    case 'vc-hcm-staging':
      currentRegion = 'vc-hcm'
      currentEnvironment = 'staging'
      break
    case 'hn-staging2':
      currentRegion = 'hn'
      currentEnvironment = 'staging2'
      break
    case 'hcm-staging2':
      currentRegion = 'hcm'
      currentEnvironment = 'staging2'
      break
    case 'staging2':
      currentRegion = 'hn'
      currentEnvironment = 'staging2'
      break
    case 'vc-hanoi-staging2':
      currentRegion = 'vc-hanoi'
      currentEnvironment = 'staging2'
      break
    case 'vc-hcm-staging2':
      currentRegion = 'vc-hcm'
      currentEnvironment = 'staging2'
      break
    case 'vc-hanoi':
      currentRegion = 'vc-hanoi'
      currentEnvironment = 'manage'
      break
    case 'vc-hcm':
      currentRegion = 'vc-hcm'
      currentEnvironment = 'manage'
      break
    case 'hn':
      currentRegion = 'hn'
      currentEnvironment = 'manage'
      break
    case 'hcm':
      currentRegion = 'hcm'
      currentEnvironment = 'manage'
      break
    case 'manage':
      currentRegion = 'hn'
      currentEnvironment = 'manage'
      break
    default:
      currentRegion = 'hn'
      currentEnvironment = 'manage'
  }

  const json = require('../../bizfly-ui-resources/json/header.json')
  const listServices = Object.assign({}, json.listServices)
  const whitelistServices = [
    {
      createUrl: '#',
      isGlobal: true,
      slug: 'billing',
      name: 'Billing',
      url: '/billing'
    },
    {
      createUrl: '#',
      isGlobal: false,
      slug: 'dashboard',
      name: 'Dashboard',
      url: '/dashboard',
      'availabilityRegions': [
        'hn', 'hcm'
      ]
    }
  ]

  let services = Object.values(listServices).map(service => service.list)
  services = [].concat(...services)
    .filter(service => !service.hide && service.url !== '#')

  const flattenListServices = [...services, ...whitelistServices]

  const currentService = flattenListServices.find(service => {
    return service.slug === slug
  })

  const userData = await userServices.getUserInfo(currentEnvironment)

  if (!currentService.supportIAM) {
    await projectServices.setDefaultProject(currentEnvironment, userData.data.tenant_id)
  }

  const userLastRegion = userData.data.last_region.toLowerCase()

  if (!currentService.isGlobal && !Object.keys(CONSTANTS.REGIONS).includes(currentRegion)) {
    let subDomain = `${userLastRegion}-${currentEnvironment}.manage`
    if (currentEnvironment === 'manage') {
      subDomain = `${userLastRegion}.${currentEnvironment}`
    } else if (currentEnvironment === 'staging2') {
      subDomain = `${userLastRegion}-staging2`
    }
    window.location.assign(`https://${subDomain}.bizflycloud.vn${window.location.pathname}${window.location.search}`)
    return
  }

  if (!currentService.isGlobal && currentRegion !== userLastRegion) {
    const data = { last_region: currentRegion.toUpperCase() }
    fetch(`https://${currentEnvironment}.bizflycloud.vn/account/api/users`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then((json) => { })
  }
}

// What should happen if the user installs the library as a plugin
function install(Vue) {
  Vue.component('BizflyHeader', BizflyHeader)
  Vue.component('BizflyButton', BizflyButton)
  Vue.component('BizflyButtonGroup', BizflyButtonGroup)
  Vue.component('BizflyTabs', BizflyTabs)
  Vue.component('BizflyTabPane', BizflyTabPane)
  Vue.component('BizflyScrollBar', BizflyScrollBar)
  Vue.component('BizflyTable', BizflyTable)
  Vue.component('BizflyTableColumn', BizflyTableColumn)
  Vue.component('BizflyMenu', BizflyMenu)
  Vue.component('BizflyMenuItem', BizflyMenuItem)
  Vue.component('BizflyStatus', BizflyStatus)
  Vue.component('BizflyContainer', BizflyContainer)
  Vue.component('BizflyRow', BizflyRow)
  Vue.component('BizflyCol', BizflyCol)
  Vue.component('BizflyTitle', BizflyTitle)
  Vue.component('BizflyLoader', BizflyLoaderPage)
  Vue.component('BizflyPagination', BizflyPagination)
  Vue.component('BizflyBetaRibbon', BizflyBetaRibbon)
  Vue.component('BizflyAlert', BizflyAlert)
  Vue.component('BizflyAutocomplete', BizflyAutocomplete)
  Vue.component('BizflyCascader', BizflyCascader)
  Vue.component('BizflyCheckbox', BizflyCheckbox)
  Vue.component('BizflyCheckboxGroup', BizflyCheckboxGroup)
  Vue.component('BizflyDatePicker', BizflyDatePicker)
  Vue.component('BizflyForm', BizflyForm)
  Vue.component('BizflyFormItem', BizflyFormItem)
  Vue.component('BizflyInput', BizflyInput)
  Vue.component('BizflyInputNumber', BizflyInputNumber)
  Vue.component('BizflyOption', BizflyOption)
  Vue.component('BizflyOptionGroup', BizflyOptionGroup)
  Vue.component('BizflyRadio', BizflyRadio)
  Vue.component('BizflyRadioGroup', BizflyRadioGroup)
  Vue.component('BizflySelect', BizflySelect)
  Vue.component('BizflySlider', BizflySlider)
  Vue.component('BizflySwitch', BizflySwitch)
  Vue.component('BizflyTimePicker', BizflyTimePicker)
  Vue.component('BizflyTimeSelect', BizflyTimeSelect)
  Vue.component('BizflyTransfer', BizflyTransfer)
  Vue.component('BizflyUpload', BizflyUpload)
  Vue.component('BizflyFaq', BizflyFaq)
  Vue.component('BizflyDialog', BizflyDialog)
  Vue.component('BizflyCard', BizflyCard)
  Vue.component('BizflyTooltip', BizflyTooltip)
  Vue.component('BizflySteps', BizflySteps)
  Vue.component('BizflyCustomSelect', BizflyCustomSelect)
  Vue.component('BizflyUpgradeDialog', BizflyUpgradeDialog)
  Vue.component('BizflyIcon', BizflyIcon)
  Vue.component('BizflyTag', BizflyTag)
  Vue.component('BizflyProgress', BizflyProgress)
  Vue.component('BizflyDropdown', BizflyDropdown)
  Vue.component('BizflyPopover', BizflyPopover)
  Vue.component('BizflyCollapse', BizflyCollapse)
  Vue.component('BizflyCollapseItem', BizflyCollapseItem)
  Vue.component('BizflyDropdownMenu', BizflyDropdownMenu)
  Vue.component('BizflyDropdownItem', BizflyDropdownItem)
  Vue.component('BizflyFeedback', BizflyFeedback)
  Vue.component('BizflyReleaseNotes', BizflyReleaseNotes)
  Vue.component('BizflyBreadcrumb', BizflyBreadcrumb)
  Vue.component('BizflyBreadcrumbItem', BizflyBreadcrumbItem)
  Vue.component('BizflySupportBox', BizflySupportBox)
  Vue.component('BizflySuggestionDialog', BizflySuggestionDialog)
  Vue.component('BizflyConfirmPaymentDialog', BizflyConfirmPaymentDialog)
  Vue.component('BizflyError500', BizflyError500)
  Vue.component('BizflyError502', BizflyError502)
  Vue.component('BizflyError404', BizflyError404)
  Vue.component('BizflyError403', BizflyError403)
  Vue.component('BizflyManualSslForm', BizflyManualSslForm)
  Vue.component('BizflyAutoSslForm', BizflyAutoSslForm)
  Vue.component('BizflySelectTree', BizflySelectTree)
  Vue.component('BizflyBacktop', BizflyBacktop)
  Vue.component('BizflyUserRegions', BizflyUserRegions)
  Vue.component('BizflyNavMenu', BizflyNavMenu)
  Vue.component('BizflyNavMenuItem', BizflyNavMenuItem)
  Vue.component('BizflyNavMenuItemGroup', BizflyNavMenuItemGroup)
  Vue.component('BizflySubmenu', BizflySubmenu)
  Vue.component('BizflyTree', BizflyTree)
  Vue.component('BizflySupportQuotas', BizflySupportQuotas)
  Vue.component('BizflySupportDialog', BizflySupportDialog)
  Vue.component('BizflyUsingService', BizflyUsingService)
  Vue.component('BizflySkeletonLoading', BizflySkeletonLoading)
}

// Export the library as a plugin
export default { install, checkUserRegion, Notification, Message }
