<template>
  <div class="bizfly-faq">
    <bizfly-card class="box-card" shadow="never">
      <h4 class="fw-6 mb-3">{{ title }}</h4>
      <ol class="pl-3">
        <li v-for="(item, index) in items" :key="index">
          <a :href="item.link" target="_blank" class="mb-0">{{ item.text }}</a>
        </li>
      </ol>
    </bizfly-card>
    <div class="bizfly-faq__footer text-right">
      <a href="https://docs.bizflycloud.vn/" target="_blank">
        Xem thêm
        trên Cloud Support
      </a>
    </div>
  </div>
</template>

<script>
import BizflyCard from '../Card/index'
export default {
  name: 'BizflyFaq',
  components: {
    // Notifications,
    BizflyCard
  },
  props: {
    title: {
      type: String, required: true
    },
    items: {
      type: Array, required: true
    }
  }
}
</script>
