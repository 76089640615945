<script>
import { Tooltip } from 'element-ui'

export default {
  name: 'BizflyTooltip',
  extends: Tooltip,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-tooltip'
    },
    popperClass: {
      type: String,
      default: 'bizfly-ui bizfly-tooltip__popper'
    }
  }
}
</script>
