<script>
import { Menu } from 'element-ui'

export default {
  name: 'BizflyNavMenu',
  extends: Menu,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-nav-menu'
    }
  }
}
</script>
