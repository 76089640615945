<template>
  <bizfly-scroll-bar max-height="435px">
    <div v-for="(item, index) in createList" :key="index" class="bizfly-header__service-group">
      <p class="bizfly-header__service-group__group-title">{{ item[0].split('_').join(' ') }}</p>
      <bizfly-dropdown-item
        v-for="(subItem, _index) in item[1]"
        :key="_index"
        class="dropdown-item"
      >
        <a :href="subItem.createUrl">
          <i class="bizfly-icons mr-2" :class="subItem.icon" />
          {{ subItem.createName || subItem.name }}
        </a>
      </bizfly-dropdown-item>
    </div>
  </bizfly-scroll-bar>
</template>

<script>
import BizflyScrollBar from '../ScrollBar'
import BizflyDropdownItem from '../DropdownItem/index'

export default {
  name: 'CreateServiceMenu',
  components: {
    BizflyScrollBar,
    BizflyDropdownItem
  },
  props: {
    listServices: {
      type: Object,
      required: true
    }
  },
  computed: {
    createList() {
      const list = Object.entries(this.listServices)
      const filteredList = []
      for (let i = 0; i < list.length; i += 1) {
        let emptyList = true
        const childList = []
        for (let j = 0; j < list[i][1].list.length; j += 1) {
          if (list[i][1].list[j].createUrl !== '#') {
            emptyList = false
            childList.push(list[i][1].list[j])
          }
        }
        if (!emptyList) filteredList.push([list[i][0], childList])
      }
      return filteredList
    }
  }
}
</script>
