<script>
import { DatePicker } from 'element-ui'

export default {
  name: 'BizflyDatePicker',
  extends: DatePicker,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-date-picker'
    }
  }
}
</script>
