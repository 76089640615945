<script>
import { TimePicker } from 'element-ui'

export default {
  name: 'BizflyTimePicker',
  extends: TimePicker,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-time-picker'
    }
  }
}
</script>
