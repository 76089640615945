<script>
import { Form } from 'element-ui'

export default {
  name: 'BizflyForm',
  extends: Form,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-form'
    }
  }
}
</script>
