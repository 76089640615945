<script>
import { TimeSelect } from 'element-ui'

export default {
  name: 'BizflyTimeSelect',
  extends: TimeSelect,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-time-select'
    }
  }
}
</script>
