<template>
  <div class="bizfly-header__mega-menu__container__content">
    <div
      v-for="(column, index) in orderedListServices"
      :key="index"
      class="bizfly-header__mega-menu__container__content__column"
    >
      <div
        v-for="(group, groupIndex) in column"
        :key="groupIndex"
        class="bizfly-header__mega-menu__container__content__column__group"
      >
        <div class="bizfly-header__mega-menu__container__content__column__group--title">{{ group.name }}</div>
        <div class="bizfly-header__mega-menu__container__content__column__group--list">
          <a
            v-for="(item, itemIndex) in group.services.filter(item => { return !item.hide })"
            :key="itemIndex"
            :href="linkToServices(item)"
          >
            <div
              class="dropdown-item"
              :class="[
                { 'beta': item.isBeta },
              ]"
            >
              <i class="bizfly-icons" :class="item.icon" />
              <div class="bizfly-header__mega-menu__container__content__column__group--list__text">
                <span>{{ item.name }}</span> {{ item.url === '#' ? '(Coming soon)' : '' }}
                <p>{{ item.describe }}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MegaMenu',
  props: {
    listServices: {
      type: Object,
      required: true
    },
    order: {
      type: Array,
      required: true
    },
    currentRegion: {
      type: String,
      default: 'hn'
    },
    currentEnvironment: {
      type: String,
      default: 'hn'
    }
  },
  data() {
    return {
      regionVisible: false,
      selectedService: null,
      ignoredSubDomains: [
        'bizfly-ui',
        'local'
      ]
    }
  },
  computed: {
    orderedListServices() {
      const list = [[], [], []]
      for (let i = 0; i < this.order.length; i += 1) {
        for (let j = 0; j < this.order[i].length; j += 1) {
          list[i].push({
            name: this.listServices[this.order[i][j]].name,
            services: this.listServices[this.order[i][j]].list
          })
        }
      }
      return list
    }
  },
  methods: {
    linkToServices(service) {
      let href = '#'
      if (service.isGlobal) {
        href = `https://${this.currentEnvironment}.bizflycloud.vn/${service.slug}`
      } else {
        if (service.availabilityRegions) {
          if (service.availabilityRegions.length > 0) {
            const checkValidRegion = service.availabilityRegions.findIndex((r) => r === this.currentRegion)
            if (checkValidRegion !== -1) {
              if (this.currentEnvironment === 'manage') {
                href = `https://${this.currentRegion}.manage.bizflycloud.vn/${service.slug}`
              } else {
                href = `https://${this.currentRegion}-${this.currentEnvironment}.manage.bizflycloud.vn/${service.slug}`
              }
            } else {
              if (this.currentEnvironment === 'manage') {
                href = `https://${service.availabilityRegions[0]}.manage.bizflycloud.vn/${service.slug}`
              } else {
                href = `https://${service.availabilityRegions[0]}-${this.currentEnvironment}.manage.bizflycloud.vn/${service.slug}`
              }
            }
          }
        }
      }
      return href
    }
  }
}
</script>
