<script>
import { CollapseItem } from 'element-ui'

export default {
  name: 'BizflyCollapseItem',
  extends: CollapseItem,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-collapse-item'
    }
  }
}
</script>
