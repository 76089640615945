<script>
import { Card } from 'element-ui'

export default {
  name: 'BizflyCard',
  extends: Card,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-card'
    }
  }
}
</script>
