<template>
  <bizfly-container class="bizfly-ui old-ui-error-page">
    <div class="content text-center">
      <img v-if="svg500" :src="svg500" class="image-error">
      <h1 class="text-center">Lỗi!</h1>
      <h3 class="text-center">Đã có lỗi xảy ra với hệ thống của chúng tôi.</h3>
      <h3 class="text-center">Vui lòng thử lại thao tác hoặc gửi thông tin cần hỗ trợ cho chúng tôi:</h3>
      <report-form :user="user" />
      <bizfly-button type="text" @click="goBackHome">Quay về trang chủ</bizfly-button>
    </div>
  </bizfly-container>
</template>

<script>
import ReportForm from '../form'
import BizflyContainer from '../../Container/index'
import BizflyButton from '../../Button/index'
import svg500 from '../../../assets/images/500.svg'

export default {
  name: 'BizflyError500',
  components: {
    ReportForm,
    BizflyContainer,
    BizflyButton
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      svg500
    }
  },
  methods: {
    goBackHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
