<script>
import { TabPane } from 'element-ui'

export default {
  name: 'BizflyTabPane',
  extends: TabPane,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-tab-pane'
    }
  }
}
</script>
