<script>
import { Dropdown } from 'element-ui'

export default {
  name: 'BizflyDropdown',
  extends: Dropdown,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-dropdown'
    }
  }
}
</script>
