<script>
import { Divider } from 'element-ui'

export default {
  name: 'BizflyDivider',
  extends: Divider,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-divider'
    }
  }
}
</script>
