<script>
import { DropdownMenu } from 'element-ui'

export default {
  name: 'BizflyDropdownMenu',
  extends: DropdownMenu,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-dropdown-menu'
    }
  }
}
</script>
