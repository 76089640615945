<script>
import { CheckboxGroup } from 'element-ui'

export default {
  name: 'BizflyCheckboxGroup',
  extends: CheckboxGroup,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-checkbox-group'
    }
  }
}
</script>
