<script>
import { Avatar } from 'element-ui'

export default {
  name: 'BizflyAvatar',
  extends: Avatar,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-avatar'
    }
  }
}
</script>
