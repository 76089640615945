<template>
  <bizfly-dialog
    class="bizfly-ui bizfly-upgrade-dialog"
    :title="`${dialogTitle} ${data.accountType === 'pre-paid' ? 'trả phí' : ''}`"
    :visible.sync="dialogVisible"
    :width="dialogWidth"
    :before-close="closeDialog"
  >
    <bizfly-row :gutter="30">
      <bizfly-col
        v-if="dialogType === 'select-plan'"
        :span="data.accountType === 'pre-paid' ? 11 : 24"
      >
        <div class="plan-detail">
          <slot name="plan" />
        </div>
      </bizfly-col>
      <bizfly-col
        v-if="data.accountType === 'pre-paid'"
        :span="dialogType === 'select-plan' ? 13 : 24"
      >
        <h3 v-if="dialogType === 'select-plan'">Thông tin thanh toán</h3>
        <b class="mb-2 ml-2">
          Loại tài khoản: {{ data.accountType === "pre-paid" ? 'Trả trước' : 'Trả sau' }}
        </b>
        <upgrade-info
          :visible="dialogVisible"
          :data="data"
          @changeButton="changeButton"
          @changeCurrentCharge="changeCurrentCharge"
        />
        <p class="note mt-3">
          <slot name="custom" />
        </p>
      </bizfly-col>
    </bizfly-row>
    <bizfly-row v-if="data.accountType !== 'pre-paid'" class="mt-2">
      <b class="mb-2 ml-2">Loại tài khoản: Trả sau</b>
      <p class="note mt-2">
        <span class="text-danger">*</span> Quý khách có thể xem thông tin chi tiết hóa đơn
        <b>
          <a href="/billing/histories" target="_blank">tại đây</a>
        </b>
      </p>
    </bizfly-row>
    <span slot="footer" class="dialog-footer">
      <bizfly-button :disabled="loading" @click="closeDialog">Hủy bỏ</bizfly-button>
      <a
        v-if="chargeButton && !whitelist && data.accountType === 'pre-paid'"
        class="el-button el-button--primary bizfly-ui bizfly-button"
        :href="`/billing?price=${currentCharge}`"
      >Nạp tiền</a>
      <bizfly-button v-else :loading="loading" type="primary" @click="confirmDialog">Xác nhận</bizfly-button>
    </span>
  </bizfly-dialog>
</template>

<script>
import BizflyRow from '../Row/index'
import BizflyCol from '../Col/index'
import BizflyButton from '../Button/index'
import BizflyDialog from '../Dialog/index'
import UpgradeInfo from './UpgradeInfo'

export default {
  name: 'BizflyUpgradeDialog',
  components: {
    BizflyRow,
    BizflyCol,
    BizflyButton,
    BizflyDialog,
    UpgradeInfo
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    whitelist: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: 'Xác nhận nâng cấp tài khoản '
    },
    dialogVisible: {
      default: false,
      type: Boolean
    },
    dialogType: {
      required: true,
      type: String,
      default: 'upgrade',
      validator(value) {
        return ['upgrade', 'select-plan'].indexOf(value) !== -1
      }
    },
    data: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      chargeButton: true,
      currentCharge: 0
    }
  },
  computed: {
    dialogWidth() {
      if (this.dialogType === 'select-plan') {
        if (this.data.accountType !== 'pre-paid') {
          return '550px'
        }
        return '1000px'
      }
      return '550px'
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },

    confirmDialog() {
      this.$emit('confirmDialog')
    },
    changeButton(status) {
      this.chargeButton = status
    },
    changeCurrentCharge(charge) {
      this.currentCharge = charge
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
