<template>
  <li id="switch-project" class="bizfly-header--services__service">
    <el-dropdown placement="bottom-end" trigger="click">
      <bizfly-row type="flex" align="middle" class="dropdown">
        <b class="mr-1">Project:</b>
        <span class="mr-1">{{ currentProject === userData.email ? 'Mặc định' : currentProject }}</span>
        <span type="info" size="mini" effect="dark">({{ currentProject !== userData.email ? role : 'Owner' }})</span>
      </bizfly-row>
      <el-dropdown-menu slot="dropdown">
        <bizfly-scroll-bar max-height="300px">
          <el-dropdown-item
            v-for="project in projects"
            :key="project.index"
            :disabled="userData.last_project === project.origin_name || projects.length === 1 || !currentService.supportIAM"
            @click.native="switchProject(project)"
          >
            <bizfly-row type="flex" align="middle" justify="space-between">
              <span class="mr-2">{{ project.origin_name === userData.email ? 'Mặc định' : project.name }}</span>
              <bizfly-tag v-if="project.origin_name !== userData.email" type="info" size="mini" effect="dark">{{ project.name === userData.email ? 'Owner' : project.role }}</bizfly-tag>
            </bizfly-row>
          </el-dropdown-item>
        </bizfly-scroll-bar>
        <el-dropdown-item divided class="create-project">
          <a href="/iam/projects?is_create=true"><b>Khởi tạo Project</b></a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

  </li>
</template>

<script>
import BizflyRow from '../Row'
import BizflyTag from '../Tag'
import BizflyScrollBar from '../ScrollBar'
import NProgress from 'nprogress'
export default {
  name: 'SwitchProject',
  components: {
    BizflyRow,
    BizflyTag,
    BizflyScrollBar
  },
  props: {
    userData: Object,
    projects: Array,
    environment: String,
    currentService: Object
  },
  data() {
    return {
    }
  },
  computed: {
    currentProject() {
      try {
        if (this.userData.last_project && this.userData.last_project.trim() !== '') {
          if (this.projects.find((p) => (p.name === p.origin_name && p.origin_name === this.userData.last_project))) {
            return this.userData.last_project
          } else {
            return this.projects.find((p) => p.origin_name === this.userData.last_project).name
          }
        } else {
          return this.userData.email
        }
      } catch (error) {
        return 'Măc định'
      }
    },

    role() {
      try {
        return this.projects.find((p) => p.origin_name === this.userData.last_project).role
      } catch (error) {
        return 'Owner'
      }
    }
  },
  methods: {
    switchProject(project) {
      NProgress.configure({ showSpinner: false })
      NProgress.start()
      const url = `https://${this.environment}.bizflycloud.vn`
      fetch(`${url}/account/api/user/project/${project.uuid.split('-').join('')}`, { credentials: 'include', method: 'PATCH' })
        .then(() => {
          NProgress.done()
          window.location.reload()
        }).catch(() => {
          this.$notify({
            showClose: true,
            title: 'Thất bại',
            message: 'Quý khách không có quyền truy cập vào project này.',
            type: 'error',
            customClass: 'error'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-project{
  line-height: 1;
  a {
    display: block;
    width: 100%;
    text-decoration: none !important;
    line-height: 36px;
    color: #676767;
  }
  &:hover{
    a{
      color: #66b1ff;
    }
  }
}
</style>
