<template>
  <bizfly-container class="bizfly-ui old-ui-error-page">
    <div class="content text-center">
      <img v-if="svg403" :src="svg403" class="image-error">
      <h1 class="text-center">Truy cập bị từ chối!</h1>
      <div v-if="serviceName === 'Webmail'">
        <div v-if="isAdmin">
          <h3 class="text-center">
            Bạn đang đăng nhập Webmail bằng tài khoản quản trị viên
            <b
              class="highlight"
            >{{ adminEmail }}</b>
          </h3>
          <h3
            v-if="domains.length === 0"
            class="text-center"
          >Vui lòng tạo tên miền và tài khoản email để sử dụng.</h3>
          <h3 v-else class="text-center">
            Vui lòng chuyển sang tài khoản với tên miền dạng
            <b class="highlight">{{ listDomains }}</b> để tiếp tục.
          </h3>
        </div>
        <div v-else>
          <h3>
            Tài khoản
            <b class="highlight">{{ user.email }}</b> của bạn không có quyền đăng nhập vào Webmail.
          </h3>
          <h3>
            Liên hệ với quản trị viên
            <b class="highlight">{{ adminEmail }}</b> để được hỗ trợ.
          </h3>
        </div>
      </div>
      <div v-else>
        <h3 class="text-center">Bạn không được quyền truy cập vào trang này,</h3>
        <h3 class="text-center">Vui lòng Quay lại hoặc thử đường link khác.</h3>
      </div>
      <bizfly-button type="primary" class="mt-3" @click="$emit('logout')">
        <b>Đăng nhập với tài khoản khác</b>
      </bizfly-button>
    </div>
  </bizfly-container>
</template>

<script>
import BizflyButton from '../../Button/index'
import BizflyContainer from '../../Container/index'
import svg403 from '../../../assets/images/403.svg'
export default {
  name: 'BizflyError403',
  components: {
    BizflyButton,
    BizflyContainer
  },
  props: {
    serviceName: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    user: {
      type: Object,
      required: true
    },
    adminEmail: {
      type: String,
      required: false
    },
    domains: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      svg403
    }
  },
  computed: {
    listDomains() {
      return this.domains.join(',')
    }
  }
}
</script>
