<script>
import { Breadcrumb } from 'element-ui'

export default {
  name: 'BizflyBreadcrumb',
  extends: Breadcrumb,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-breadcrumb'
    }
  }
}
</script>
