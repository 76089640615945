<template>
  <icon
    :name="name"
    :scale="scale"
    :spin="spin"
    :pulse="pulse"
    :inverse="inverse"
    :flip="flip"
    :label="label"
    :title="title"
    @click="$emit('click')"
  />
</template>

<script>
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'

export default {
  name: 'BizflyIcon',
  components: { Icon },
  props: {
    name: {
      required: true,
      type: String
    },
    scale: {
      type: [String, Number],
      default: 1
    },
    spin: {
      type: Boolean,
      default: false
    },
    pulse: {
      type: Boolean,
      default: false
    },
    inverse: {
      type: Boolean,
      default: false
    },
    flip: {
      type: String,
      validator(value) {
        return ['vertical', 'horizontal', 'both'].indexOf(value) !== -1
      }
    },
    label: {
      type: String
    },
    title: {
      type: String
    }
  }
}
</script>
