<template>
  <div class="form text-center mt-3">
    <bizfly-form :model="form">
      <bizfly-form-item>
        <bizfly-input
          v-model="form.content"
          type="textarea"
          placeholder="Mô tả nội dung bạn cần hỗ trợ tại đây ..."
          rows="7"
          resize="none"
        />
      </bizfly-form-item>
      <bizfly-form-item>
        <bizfly-button type="primary" @click="sendInfo">
          <b>GỬI THÔNG TIN</b>
        </bizfly-button>
      </bizfly-form-item>
      <bizfly-form-item>
        <h4 class="mt-1">Hoặc hotline: 024 7302 8888 để được hỗ trợ 24/7</h4>
      </bizfly-form-item>
    </bizfly-form>
  </div>
</template>

<script>
import BizflyForm from '../Form/index'
import BizflyFormItem from '../FormItem/index'
import BizflyInput from '../Input/index'
import BizflyButton from '../Button/index'
export default {
  name: 'FormInfomation',
  components: {
    BizflyForm,
    BizflyFormItem,
    BizflyInput,
    BizflyButton
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        content: ''
      },
      endpoint: {
        feedback: 'https://manage.bizflycloud.vn/ticket/send'
      },
      window
    }
  },
  methods: {
    sendInfo() {
      const data = new FormData()
      data.append('FullName', this.user.fullname || this.user.name)
      data.append('Email', this.user.email)
      data.append('Content', decodeURI(this.form.content))
      data.append('URL', window.location.href)
      fetch(this.endpoint.feedback, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        body: data
      }).then((response) => {
        this.form.content = ''
        this.$notify({
          showClose: true,
          title: 'Thành công',
          message: 'Cảm ơn quý khách đã gửi thông tin!',
          type: 'success',
          customClass: 'success'
        })
      }).catch(() => {
        this.$notify({
          showClose: true,
          title: 'Thất bại',
          message: 'Gửi thông tin thất bại, vui lòng thử lại sau',
          type: 'error',
          customClass: 'error'
        })
      })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
