<template>
  <div :class="['bizfly-ui bizfly-title', { icon: icon }]">
    <a v-if="typeof to === 'string'" :href="to" class="bizfly-back" />
    <router-link v-else :to="to" class="bizfly-back" />
    <div v-if="icon" class="bizfly-title__with-icon">
      <div v-if="iconClass" class="bizfly-title__icon">
        <slot name="icon" />
      </div>
      <div class="bizfly-title__title" :class="{ 'ml-3': iconClass }">
        <slot name="title" />
      </div>
    </div>
    <h3 v-else>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'BizflyTitle',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    to: {
      required: true
    },
    title: {
      type: String
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: Boolean,
      default: true
    }
  }
}
</script>
