<template>
  <bizfly-row v-if="zones">
    <bizfly-row v-if="zones.length >= 1">
      <bizfly-col
        v-for="(zone, index) in zones"
        :key="zone.short_name + index"
        :span="4"
        class="region mr-3"
        :class="{
          active: zoneShortName === zone.short_name,
          disabled: isDisabled && imageZoneName !== zone.short_name,
        }"
      >
        <div class="icon py-3" @click="selectZone(zone)">
          <img v-if="zone.icon" :src="zone.icon" alt="bizflycloud-region" class="icon icon-gray" width="50">
          <p class="mt-2">{{ zone.short_name }}</p>
        </div>
      </bizfly-col>
    </bizfly-row>
  </bizfly-row>
</template>

<script>
import BizflyRow from '../Row/index'
import BizflyCol from '../Col/index'

export default {
  name: 'UserRegions',
  components: {
    BizflyRow,
    BizflyCol
  },
  props: {
    zones: {
      type: Array,
      required: true,
      default: function() { return [] }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    imageZoneName: {
      type: String,
      default: ''
    },
    zoneShortName: {
      type: String,
      required: true
    }
  },
  methods: {
    selectZone(zone) {
      this.$emit('select-zone', zone)
    }
  }
}
</script>

<style lang="scss" scoped>
.region {
  text-align: center;
  border: 1px solid #b3b3b3;
  opacity: 0.5;
}
.region {
  &.active {
    background-color: rgba(0, 136, 204, 0.05);
    border-color: #1174d4;
    opacity: 1;
    .icon {
      filter: grayscale(0);
    }
    .zones {
      background-color: rgba(0, 136, 204, 0.05);
      border-color: #1174d4;
    }
    .zone {
      border-color: #1174d4;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}
.zones {
  border-top: 1px solid #b3b3b3;
}
.zone {
  border-right: 1px solid #b3b3b3;
  &:last-child {
    border-right: none;
  }
  &.active {
    color: #1174d4;
    font-weight: 600;
  }
}
.icon-gray {
  filter: grayscale(100%);
}
</style>
