<template>
  <div v-if="!callingAPI" class="bizfly-ui bizfly-header">
    <a href="/" class="bizfly-header--branch">
      <img :src="logo" alt>
    </a>
    <div class="bizfly-header--items">
      <ul
        v-if="!hideServices"
        class="bizfly-header--items__item bizfly-header--services"
      >
        <!-- MEGA MENU -->
        <bizfly-dropdown trigger="click" class="bizfly-header__mega-menu">
          <li
            class="
              bizfly-header--services__service
              bizfly-header__mega-menu--title
              dropdown
            "
          >
            Tất cả dịch vụ
          </li>
          <bizfly-dropdown-menu
            slot="dropdown"
            class="bizfly-header__mega-menu__container"
          >
            <mega-menu
              :list-services="listServices"
              :order="megaMenuOrder"
              :current-region="currentRegion"
              :current-environment="currentEnvironment"
            />
          </bizfly-dropdown-menu>
        </bizfly-dropdown>
        <!-- /MEGA MENU -->

        <div v-if="!setPinnedItems" class="list-group">
          <li class="bizfly-header--services__service">
            <a href="/" class>Dashboard</a>
          </li>
          <li
            v-for="service in pinnedMenu"
            :key="service.name"
            :class="['bizfly-header--services__service']"
          >
            <a :href="service.url" :class="[{ beta: service.isBeta }]">{{
              service.name
            }}</a>
          </li>
        </div>

        <div v-else class="list-group">
          <li class="bizfly-header--services__service">
            <a href="/" class>Dashboard</a>
          </li>
          <draggable
            v-model="pinnedMenu"
            tag="ul"
            group="dragDrop"
            class="list-group draggable-items"
            ghost-class="ghost"
            @start="startDragging"
            @end="endDragging"
            @change="endDragging"
          >
            <li
              v-for="service in pinnedMenu"
              :key="service.name"
              :class="['bizfly-header--services__service']"
            >
              <a href="javascript:;">{{ service.name }}</a>
            </li>
          </draggable>
        </div>

        <!-- SET PINNED ITEMS -->
        <bizfly-dropdown
          trigger="click"
          class="bizfly-header__mega-menu all-services-dropdown"
          @visible-change="handleShowListMenu"
        >
          <li
            class="
              bizfly-header--services__service
              bizfly-header__mega-menu--title
              dropdown
            "
          >
            <i class="el-icon-star-on pinned-button" />
          </li>
          <bizfly-dropdown-menu
            slot="dropdown"
            class="bizfly-header__mega-menu__container"
          >
            <list-service
              :list-services="listServices"
              :show-delete-zone="showDeleteZone"
              :pinned-menu="pinnedMenu"
            />
          </bizfly-dropdown-menu>
        </bizfly-dropdown>
        <!-- /SET PINNED ITEMS -->
      </ul>

      <ul class="bizfly-header--items__item ml-auto">
        <!-- CREATE MENU -->
        <bizfly-dropdown v-if="!hideServices" trigger="click">
          <bizfly-button
            class="dropdown"
            type="primary"
          >Khởi tạo</bizfly-button>
          <bizfly-dropdown-menu
            slot="dropdown"
            class="bizfly-menu--dropdown bizfly-header--dropdown"
          >
            <create-service-menu :list-services="listServices" />
          </bizfly-dropdown-menu>
        </bizfly-dropdown>
        <!-- /CREATE MENU -->

        <!-- Switch Project -->
        <switch-project
          v-if="
            !hideServices &&
              projects.length > 0 &&
              currentService.name !== 'IAM' &&
              !whitelistServices.map((w) => w.name).includes(currentService.name)
          "
          :user-data="userData"
          :environment="environment"
          :current-service="currentService"
          :projects="projects"
        />
        <!-- END Switch Project -->

        <li v-if="isMailAccount" class="bizfly-header--services__service">
          <a :href="userData.domain.webmail_url">Webmail</a>
        </li>

        <template v-if="!loadingRegion">
          <switch-region
            v-if="!hideServices"
            :user-data="userData"
            :environment="environment"
            :list-services="listServices"
            :regions="regions"
            :current-region="currentRegion"
            :current-environment="currentEnvironment"
          />
        </template>

        <li class="bizfly-header--services__service">
          <el-dropdown
            trigger="click"
            class="support"
            @command="handleOpenLink"
          >
            <span class="label-dropdown">
              <bizfly-icon name="question-circle" class="icon-dropdown" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="linkSupport">Hỗ trợ</el-dropdown-item>
              <el-dropdown-item
                :command="linkDocs"
              >Tài liệu HDSD</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <!--        <li v-if="!hideServices" class="bizfly-header&#45;&#45;services__service">-->
        <!--          <notifications-->
        <!--            :user-data="userData"-->
        <!--            :notify-mark-read-id="notifyMarkReadId"-->
        <!--            :notify-delete="notifyDelete"-->
        <!--            @handleSetNotifyMarkRead="handleSetNotifyMarkRead"-->
        <!--          />-->
        <!--        </li>-->
        <li class="bizfly-header--services__service">
          <bizfly-dropdown placement="bottom-end" trigger="click">
            <div class="avatar">
              <img :src="avartarImage" alt="">
              <i class="el-icon-caret-bottom arrow-down" />
            </div>
            <bizfly-dropdown-menu
              slot="dropdown"
              class="
                bizfly-menu--dropdown
                bizfly-header--dropdown
                profile-dropdown
              "
            >
              <div class="user-info text-center">
                <div class="avatar text-center">
                  <img :src="avartarImage" alt="">
                </div>
                <h3 v-if="userData.fullname">{{ userData.fullname }}</h3>
                <p>{{ userData.email }}</p>
              </div>
              <bizfly-dropdown-item divided>
                <a href="/account/profile">Hồ sơ</a>
              </bizfly-dropdown-item>
              <bizfly-dropdown-item>
                <a href="/account/security">Bảo mật</a>
              </bizfly-dropdown-item>
              <bizfly-dropdown-item v-if="!hideServices">
                <a href="/billing">Thanh toán</a>
              </bizfly-dropdown-item>
              <bizfly-dropdown-item>
                <a
                  href="https://docs.bizflycloud.vn/"
                  target="_blank"
                >Hướng dẫn sử dụng</a>
              </bizfly-dropdown-item>
              <bizfly-dropdown-item divided>
                <a @click="logout">Đăng xuất</a>
              </bizfly-dropdown-item>
            </bizfly-dropdown-menu>
          </bizfly-dropdown>
        </li>
      </ul>
    </div>
    <bizfly-feedback
      :user="userData"
      left="15px"
      bottom="15px"
      :on-left="true"
      :is-visible="isVisible"
      @close="closeFeedBack"
      @open="triggerGAEvent('Mở pop up')"
      @go-to-support-center="triggerGAEvent('Xem tài liệu')"
      @upload-image="triggerGAEvent('Upload ảnh')"
      @upload-image-failed="triggerGAEvent('Upload ảnh thất bại')"
      @send-feedback-successful="triggerGAEvent('Gửi feedback thành công')"
      @send-feedback-failed="triggerGAEvent('Gửi feedback thất bại')"
    />
    <bizfly-feedback :user="userData" left="15px" bottom="15px" />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import BizflyButton from '../Button/index'
import BizflyDropdown from '../Dropdown/index'
import BizflyDropdownItem from '../DropdownItem/index'
import BizflyDropdownMenu from '../DropdownMenu/index'
import BizflyIcon from '../Icon/index'
import MegaMenu from './MegaMenu'
import ListService from './ListService'
import CreateServiceMenu from './CreateServiceMenu'
import BizflyFeedback from '../Feedback/index'
// import Notifications from './Notifications'
import SwitchProject from './SwitchProject'
import SwitchRegion from './SwitchRegion'
import MD5 from 'crypto-js/md5'
// import userServices from '../../lib/user'

export default {
  name: 'BizflyHeader',
  components: {
    // Notifications,
    BizflyButton,
    BizflyDropdown,
    BizflyDropdownItem,
    BizflyDropdownMenu,
    BizflyIcon,
    MegaMenu,
    CreateServiceMenu,
    ListService,
    draggable,
    BizflyFeedback,
    SwitchProject,
    SwitchRegion
  },
  props: {
    reloadProject: Number,
    userData: {
      required: true,
      type: Object
    },
    notifyMarkReadId: {
      type: String
    },
    notifyDelete: {
      type: Object
    },
    devService: {
      type: Object
    },
    hideServices: {
      type: Boolean,
      default: false
    },
    environment: {
      required: true,
      type: String
    },
    ga: {
      type: Object
    }
  },
  data() {
    return {
      isVisible: false,
      callingAPI: true,
      listServices: {},
      pinnedMenu: [],
      megaMenuOrder: [],
      projects: [],
      logo: '',
      setPinnedItems: false,
      showDeleteZone: false,
      whitelistServices: [
        {
          createUrl: '#',
          isGlobal: true,
          slug: 'billing',
          name: 'Billing',
          url: '/billing'
        },
        {
          createUrl: '#',
          isGlobal: false,
          slug: 'dashboard',
          name: 'Dashboard',
          url: '/dashboard'
        }
      ],
      linkSupport: 'https://manage.bizflycloud.vn/ticket/',
      linkDocs: 'https://docs.bizflycloud.vn/',
      regions: {},
      currentRegion: 'hn',
      currentEnvironment: 'manage',
      loadingRegion: true
    }
  },
  computed: {
    isMailAccount() {
      return this.hideServices && this.userData.type !== 'Owner'
    },
    flattenListServices() {
      let services = Object.values(this.listServices).map(service => service.list)
      services = [].concat(...services)
        .filter(service => !service.hide && service.url !== '#') // Flatten array
      return [...services, ...this.whitelistServices]
    },
    currentService() {
      const url = window.location.href
      // const url = 'https://manage.bizflycloud.vn//iaas-cloud/volumes/create'
      const slug = url.split('/')[3]
      const service = this.flattenListServices.find(service => {
        return service.slug === slug
      })
      return service || {
        'id': 1,
        'name': 'Cloud Server',
        'url': '/iaas-cloud/servers',
        'createUrl': '/iaas-cloud/servers/create',
        'icon': 'bizfly-icon bizfly-icon__server',
        'describe': 'Khởi tạo Cloud Server',
        'isGlobal': false,
        'supportIAM': true
      }
    },
    avartarImage() {
      let mediacdnUrl = ''
      switch (this.environment) {
        case 'staging':
          mediacdnUrl = 'https://stag-vccloud-static.mediacdn.vn'
          break
        case 'manage' :
          mediacdnUrl = 'https://vccloud-static.mediacdn.vn'
          break
        default:
          mediacdnUrl = 'https://stag-vccloud-static.mediacdn.vn'
          break
      }
      const md5Image = MD5(this.userData.email).toString()
      return `${mediacdnUrl}/avatar/${md5Image}/avatar.png?default=/avatar/default.png&time=${new Date().getTime()}`
    }
  },
  watch: {
    'reloadProject': 'loadProjects'
  },
  async created() {
    const subDomain = window.location.host.split('.')[0]
    let currentEnvironment = 'manage'
    let currentRegion = 'hn'
    switch (subDomain) {
      case 'local':
        currentRegion = 'hn'
        currentEnvironment = 'staging'
        break
      case 'hn-local':
        currentRegion = 'hn'
        currentEnvironment = 'staging'
        break
      case 'hcm-local':
        currentRegion = 'hcm'
        currentEnvironment = 'staging'
        break
      case 'vc-hanoi-local':
        currentRegion = 'vc-hanoi'
        currentEnvironment = 'staging'
        break
      case 'vc-hcm-local':
        currentRegion = 'vc-hcm'
        currentEnvironment = 'staging'
        break
      case 'hn-staging':
        currentRegion = 'hn'
        currentEnvironment = 'staging'
        break
      case 'hcm-staging':
        currentRegion = 'hcm'
        currentEnvironment = 'staging'
        break
      case 'staging':
        currentRegion = 'hn'
        currentEnvironment = 'staging'
        break
      case 'vc-hanoi-staging':
        currentRegion = 'vc-hanoi'
        currentEnvironment = 'staging'
        break
      case 'vc-hcm-staging':
        currentRegion = 'vc-hcm'
        currentEnvironment = 'staging'
        break
      case 'hn-staging2':
        currentRegion = 'hn'
        currentEnvironment = 'staging2'
        break
      case 'hcm-staging2':
        currentRegion = 'hcm'
        currentEnvironment = 'staging2'
        break
      case 'staging2':
        currentRegion = 'hn'
        currentEnvironment = 'staging2'
        break
      case 'vc-hanoi-staging2':
        currentRegion = 'vc-hanoi'
        currentEnvironment = 'staging2'
        break
      case 'vc-hcm-staging2':
        currentRegion = 'vc-hcm'
        currentEnvironment = 'staging2'
        break
      case 'vc-hanoi':
        currentRegion = 'vc-hanoi'
        currentEnvironment = 'manage'
        break
      case 'vc-hcm':
        currentRegion = 'vc-hcm'
        currentEnvironment = 'manage'
        break
      case 'hn':
        currentRegion = 'hn'
        currentEnvironment = 'manage'
        break
      case 'hcm':
        currentRegion = 'hcm'
        currentEnvironment = 'manage'
        break
      case 'manage':
        currentRegion = 'hn'
        currentEnvironment = 'manage'
        break
      default:
        currentRegion = 'hn'
        currentEnvironment = 'manage'
    }
    this.currentRegion = currentRegion
    this.currentEnvironment = currentEnvironment
    fetch(`https://${currentEnvironment}.bizflycloud.vn/account/api/users/info?only=user_regions`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(userInfo => userInfo.json())
      .then((userInfo) => {
        if (userInfo) {
          if (userInfo.data) {
            if (userInfo.data.user_regions) {
              if (userInfo.data.user_regions.length > 0) {
                this.regions = {}
                userInfo.data.user_regions.map(r => {
                  if (r.code && r.name) {
                    const keyRegion = r.code.toLowerCase()
                    this.regions[keyRegion] = r.name
                  }
                })
              }
            }
          }
        }
        this.loadingRegion = false
      })
      .catch((error) => {
        this.loadingRegion = false
        console.log('error load regions', error)
      })
  },
  beforeMount() {
    this.initCrossDomainLocalStorage()
    this.loadProjects()
    this.callingAPI = true
    const json = require('../../../bizfly-ui-resources/json/header.json')
    this.listServices = Object.assign({}, json.listServices)
    // this.listServices.storage.list.splice(1, 1)
    if (this.pinnedMenu.length) {
      let services = Object.values(this.listServices).map(service => service.list)
      services = [].concat(...services)
      this.pinnedMenu.forEach(menu => {
        const sourceMenu = services.filter(service => service.name === menu.name)
        if (sourceMenu.length) {
          menu.isBeta = !!sourceMenu[0].isBeta
        }
      })
      this.setFavoriteServices(this.pinnedMenu)
    }
    this.megaMenuOrder = json.megaMenuOrder
    this.logo = json.logo

    if (this.devService && this.devService.category) {
      const service = this.devService.detail
      if (!service.icon) {
        service.icon = 'bizfly-icon--new bizfly-icon--new__blank'
      }
      this.listServices[this.devService.category].list.push(service)
    }
    this.callingAPI = false
  },
  mounted() {
    // this.initTawk()
    this.initBizflyChat()
    if (!this.hideServices) {
      var initLSInterval = setInterval(() => {
        if (window.xdLocalStorage) {
          window.xdLocalStorage.init(
            {
              /* required */
              iframeUrl: `https://${this.environment}.bizflycloud.vn/save/storage`,
              // an option function to be called right after the iframe was loaded and ready for action
              initCallback: () => {
                this.getFavoriteServices()
                clearInterval(initLSInterval)
              }
            }
          )
        }
      }, 1000)
    }
  },
  methods: {
    handleOpenLink(url) {
      window.open(url, '_blank')
    },
    initCrossDomainLocalStorage() {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://${this.environment}.bizflycloud.vn/assets/js/xdLocalStorage.min.js`
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
    },
    loadProjects() {
      if (!this.hideServices && this.currentService.name !== 'IAM' && !this.whitelistServices.map((w) => w.name).includes(this.currentService.name)) {
        const iamUrl = `https://${this.environment}.bizflycloud.vn/api/iam/v1/projects?page=1&all`
        fetch(iamUrl, { credentials: 'include' })
          .then(res => res.json())
          .then((json) => {
            if (json) {
              if (json.data) {
                if (json.data.length > 0) {
                  this.projects = json.data
                }
              }
            }
          }).catch(() => {
            this.projects = []
          })
      }
    },

    closeFeedBack() {
      this.isVisible = false
      this.triggerGAEvent('Hủy bỏ')
    },
    triggerGAEvent(action, category = 'Gửi feedback') {
      if (this.ga) {
        this.ga.event({
          eventCategory: category,
          eventAction: action
        })
      }
    },
    getFavoriteServices() {
      window.xdLocalStorage.getItem('bizflycloud_favorite_services', (data) => {
        const favoriteServices = JSON.parse(data.value)
        if (favoriteServices) {
          // const index = favoriteServices.findIndex((s) => s.slug === 'cloud-backup')
          // if (index !== -1) {
          //   favoriteServices.splice(index, 1)
          // }
          this.pinnedMenu = favoriteServices
        } else if (!this.pinnedMenu.length) {
          const json = require('../../../bizfly-ui-resources/json/header.json')
          this.pinnedMenu = json.pinnedMenu
        } else {
          this.pinnedMenu = []
        }
      })
    },
    setFavoriteServices(services) {
      window.xdLocalStorage.setItem('bizflycloud_favorite_services', JSON.stringify(services))
    },
    startDragging() {
      this.showDeleteZone = true
    },
    endDragging() {
      this.showDeleteZone = false
      this.setFavoriteServices(this.pinnedMenu)
    },
    handleShowListMenu(show) {
      this.setPinnedItems = show
    },
    logout() {
      this.$emit('logout')
    },
    handleSetNotifyMarkRead(notify) {
      this.$emit('setNotifyMarkRead', notify)
    },
    initTawk() {
      const scriptChatbox = document.createElement('script')
      const chatbox = document.createTextNode(`
          var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
           Tawk_API.visitor = {
            name  : '${this.userData.fullname}',
            email : '${this.userData.email}'
          };
          (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/55c16f855355f0e91c647bad/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
          })();
        `)
      scriptChatbox.appendChild(chatbox)
      document.body.appendChild(scriptChatbox)
    },

    initBizflyChat() {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://chat.bizfly.vn/script/dip-v2/1f56cf79-35aa-4ca6-95f2-9fc83b72de86?ref=webEmbed_00c293aeb460a61d27a763280b042370'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag)

      const userScript = document.createElement('script')
      userScript.type = 'application/javascript'
      const indentifyUser = document.createTextNode(`
        var chat_biz_user = {
          username : '${this.userData.email.replace(/[^\w\s]/gi, '_')}',
          email : '${this.userData.email}',
          name : '${this.userData.fullname || this.userData.name}',
        }
      `)

      userScript.appendChild(indentifyUser)
      script.parentNode.insertBefore(userScript, script)
    }
  }
}
</script>
