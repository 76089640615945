<script>
import { Tree } from 'element-ui'

export default {
  name: 'BizflyTree',
  extends: Tree
  // props: {
  //   customClass: {
  //     type: String,
  //     default: 'bizfly-ui bizfly-tree'
  //   }
  // }
}
</script>
