<template>
  <bizfly-form ref="form" :model="form" :rules="rules">
    <bizfly-form-item label="Name" prop="name">
      <p class="mb-2">Tên của Certificate, nên sử dụng tên có thể phân biệt được giữa các Certificate, Ví dụ tên Certificate là domain của Certificate đó.</p>
      <bizfly-input v-model="form.name" />
    </bizfly-form-item>
    <bizfly-form-item label="Certificate" prop="certificate">
      <p class="mb-2">Chứng chỉ số, có dạng chuẩn PEM.</p>
      <bizfly-input v-model="form.certificate" type="textarea" rows="5" />
    </bizfly-form-item>
    <bizfly-form-item label="Intermediates" prop="intermediates">
      <bizfly-input v-model="form.intermediates" type="textarea" rows="5" />
    </bizfly-form-item>
    <bizfly-form-item label="Private Key" prop="privateKey">
      <p class="mb-2">Khóa bí mật của chứng chỉ số đó, có định dạng chuẩn PEM.</p>
      <bizfly-input v-model="form.privateKey" type="textarea" rows="5" />
    </bizfly-form-item>
    <bizfly-form-item label="Passphrase" prop="passphrase">
      <p class="mb-2">Trong trường hợp khóa bí mật có mật khẩu thì cần nhập mật khẩu của khóa bí mật, ngược lại bỏ trống trường này.</p>
      <bizfly-input v-model="form.passphrase" />
    </bizfly-form-item>
    <bizfly-form-item class="mt-5">
      <p class="note text-danger text-center mb-3">Lưu ý: Chứng chỉ SSL cần được cập nhật lên hệ thống sau khi Upload</p>
      <bizfly-button
        :loading="loading"
        type="primary"
        class="btn-submit"
        @click="confirm"
      >Upload SSL Certificate</bizfly-button>
    </bizfly-form-item>
  </bizfly-form>
</template>

<script>
import BizflyForm from '../../Form/index'
import BizflyFormItem from '../../FormItem/index'
import BizflyInput from '../../Input/index'
import BizflyButton from '../../Button/index'
export default {
  name: 'ManualForm',
  components: {
    BizflyForm,
    BizflyFormItem,
    BizflyInput,
    BizflyButton
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        certificate: '',
        intermediates: '',
        privateKey: '',
        passphrase: ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên không được để trống.', trigger: 'blur' }
        ],
        certificate: [
          { required: true, message: 'Certificate không được để trống.', trigger: 'blur' }
        ],
        privateKey: [
          { required: true, message: 'Private Key không được để trống.', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('create', this.form)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  width: 100%;
}
p {
  font-weight: bold;
  color: #737373;
  font-size: 12px;
  line-height: 1;
}
.note {
  font-size: 14px;
}
</style>
