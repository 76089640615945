<script>
import { Tag } from 'element-ui'

export default {
  name: 'BizflyTag',
  extends: Tag,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-tag'
    }
  }
}
</script>
