<template>
  <div class="bizfly-ui bizfly-using-service">
    <loading-skeleton v-if="loading" />
    <div v-else>
      <bizfly-row v-if="resource.count > 0 || resource.status_code" type="flex" align="top" justify="start" class="title" :class="{'error': resource.status_code}">
        <div class="icon">
          <i :class="resource.icon" />
        </div>
        <a :href="resource.link" target="_blank" class="text-limit">{{ resource.name }}</a>
        <span v-if="resource.count > 0">({{ resource.count }})</span>
      </bizfly-row>
      <bizfly-row class="resources">
        <div v-if="resource.status_code" class="error-api">
          <p><b>{{ resource.status_code }}</b>: {{ resource.message }}</p>
        </div>
        <div v-else-if="resource.count > 0" class="list-resource">
          <ul v-if="isShowMore">
            <li v-for="item in resource.items" :key="item.index" class="text-limit">
              <bizfly-status
                v-if="item.status"
                :use-tooltip="item.status.content ? true : false"
                :tooltip-content="item.status.content"
                :status="item.status.type"
                class="mr-2"
              />
              <a :href="item.link" target="_blank" class="text-limit">{{ item.name }}</a>
            </li>
          </ul>
          <ul v-else>
            <li v-for="item in resource.items.slice(0,5)" :key="item.index" class="text-limit">
              <bizfly-status
                v-if="item.status"
                :use-tooltip="item.status.content ? true : false"
                :tooltip-content="item.status.content"
                :status="item.status.type"
                class="mr-2"
              />
              <a :href="item.link" target="_blank" class="text-limit">{{ item.name }}</a>
            </li>
          </ul>
          <ul v-if="resource.items.length > 5" class="show-more">
            <a v-if="!isShowMore" href="javascript:;" @click="isShowMore = true">Xem thêm <bizfly-icon name="angle-down" class="ml-1" /></a>
            <a v-else href="javascript:;" @click="isShowMore = false">Thu gọn <bizfly-icon name="angle-up" class="ml-1" /></a>
          </ul>
        </div>
      </bizfly-row>
    </div>
  </div>
</template>

<script>
import BizflyRow from '../Row/index'
import BizflyStatus from '../Status/index'
import BizflyIcon from '../Icon/index'
import LoadingSkeleton from './LoadingSkeleton'
export default {
  name: 'UsingService',
  components: {
    BizflyRow,
    BizflyStatus,
    BizflyIcon,
    LoadingSkeleton
  },
  props: {
    resource: Object,
    loading: Boolean
  },
  data() {
    return {
      isShowMore: false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
