<script>
import { Select } from 'element-ui'

export default {
  name: 'BizflySelect',
  extends: Select,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-select'
    }
  }
}
</script>
