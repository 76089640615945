<script>
import { Input } from 'element-ui'

export default {
  name: 'BizflyInput',
  extends: Input,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-input'
    }
  }
}
</script>
