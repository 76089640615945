<script>
import ElSelectTree from 'el-select-tree'

export default {
  name: 'BizflySelectTree',
  extends: ElSelectTree,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-select-tree'
    }
  }
}
</script>
