<template>
  <div class="bizfly-ui bizfly-status">
    <bizfly-tooltip
      v-if="useTooltip"
      popper-class="bizfly-tooltip"
      effect="dark"
      :content="tooltipContent"
      :placement="tooltipPlacement"
    >
      <span :class="status" class="status" />
    </bizfly-tooltip>
    <span v-else :class="status" class="status" />
  </div>
</template>

<script>
import BizflyTooltip from '../Tooltip/index'

export default {
  name: 'BizflyStatus',
  components: {
    // Notifications,
    BizflyTooltip
  },
  props: {
    useTooltip: {
      type: Boolean,
      default: false
    },
    tooltipContent: {
      type: String
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom'
    },
    status: {
      type: String,
      default: 'default'
    }
  }
}
</script>
