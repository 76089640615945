<script>
import { Alert } from 'element-ui'

export default {
  name: 'BizflyAlert',
  extends: Alert,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-alert'
    }
  }
}
</script>
