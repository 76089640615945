<template>
  <div class="bizfly-ui bizfly-support-box">
    <div class="box-card mt-1 mb-4">
      <h4 class="title">yêu cầu hỗ trợ</h4>

      <div v-if="showSaleInfo" class="contact-sale">
        <p class="bizfly-support-box_heading">Nhân viên KD phụ trách:</p>
        <div class="contact-sale_info">
          <bizfly-avatar
            icon="el-icon-user-solid"
            :src="`https://vccloud-static.mediacdn.vn/avatar/${tenantID || saleInfo.email}/avatar.png?default=/avatar/default.png?time=${currentTime}`"
          />
          <div>
            <div class="name">{{ saleInfo.name }}</div>
            <div class="phone">
              Hotline:
              <a :href="`tel:${salePhone}`">
                {{ saleInfo.phone }}
                <span v-if="saleInfo.phone_ext">
                  - Ext: {{ saleInfo.phone_ext }}
                </span>
              </a>
            </div>
          </div>
        </div>
        <bizfly-divider>Hoặc</bizfly-divider>
      </div>

      <div class="mb-2">
        <p class="bizfly-support-box_heading">Email:</p>
        <p>
          <a href="mailto:support@bizflycloud.vn">support@bizflycloud.vn</a>
        </p>
      </div>
      <div class="mb-2">
        <p class="bizfly-support-box_heading">Hotline:</p>
        <p>Hà Nội: <a href="tel:02473028888">024 7302 8888</a></p>
        <p>Tp Hồ Chí Minh: <a href="tel:02873028888">028 7302 8888</a></p>
      </div>
      <div class="mb-2">
        <p class="bizfly-support-box_heading">Gửi ticket qua Website:</p>
        <p>
          <a href="https://manage.bizflycloud.vn/ticket/" target="_blank">https://manage.bizflycloud.vn/ticket/</a>
        </p>
      </div>
      <div class="mb-2">
        <p class="bizfly-support-box_heading">Hệ thống các bài viết hướng dẫn:</p>
        <p>
          <a href="https://docs.bizflycloud.vn/" target="_blank">https://docs.bizflycloud.vn/</a>
        </p>
      </div>
    </div>

    <div v-if="withFeedback" class="box-card mt-1 mb-4">
      <h4 class="title">Bạn đang gặp vấn đề?</h4>
      <div class="bizfly-support-box_feedback">
        <i class="el-icon-message" />
        <p>
          <a href="javascript:" @click="$emit('show-feedback')">Gửi ngay báo lỗi/phản hồi</a>
          cho chúng tôi về vấn đề bạn đang gặp phải để được trợ giúp.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BizflyAvatar from '../Avatar'
import BizflyDivider from '../Diviver'

export default {
  name: 'SupportBox',
  components: {
    BizflyAvatar,
    BizflyDivider
  },
  props: {
    saleApiEndpoint: {
      type: String,
      default: 'https://staging.bizflycloud.vn/account/api/sales'
    },
    withFeedback: {
      type: Boolean,
      default: true
    },
    tenantId: {
      type: String
    }
  },
  data() {
    return {
      saleInfo: null,
      showFeedback: false,
      showSaleInfo: false
    }
  },
  computed: {
    salePhone() {
      if (this.saleInfo) {
        const phone = this.saleInfo.phone.split(' ').join('') + (this.saleInfo.phone_ext ? ',' + this.saleInfo.phone_ext : '')
        return phone.replace('0', '+84')
      }
      return ''
    },
    currentTime() {
      return new Date().getTime()
    }
  },
  beforeMount() {
    this.fetchSaleInfo()
  },
  methods: {
    fetchSaleInfo() {
      fetch(this.saleApiEndpoint, {
        method: 'GET',
        credentials: 'include'
      }).then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      }).then((response) => response.json())
        .then((data) => {
          if (data.code === 200) {
            this.showSaleInfo = true
            this.saleInfo = data.sale
          }
        }).catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
