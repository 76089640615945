<script>
import { ButtonGroup } from 'element-ui'

export default {
  name: 'BizflyButtonGroup',
  extends: ButtonGroup,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-button-group'
    }
  }
}
</script>
