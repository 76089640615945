<template>
  <div
    class="bizfly-scroll-bar"
    :style="{
      maxHeight: maxHeight
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BizflyScrollBar',
  props: {
    maxHeight: {
      type: String,
      required: true
    }
  }
}
</script>
