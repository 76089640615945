<template>
  <div class="bizfly-ui bizfly-custom-select">
    <label :class="activeLabel">{{ label }}</label>
    <bizfly-select v-model="value" :name="name" placeholder @change="handleOptionChanged">
      <bizfly-option
        v-for="item in items"
        :key="item[valueField]"
        :label="item[labelField]"
        :value="item[valueField]"
      />
    </bizfly-select>
  </div>
</template>

<script>
import BizflySelect from '../Select/index'
import BizflyOption from '../Option/index'

export default {
  name: 'BizflyCustomSelect',
  components: {
    // Notifications,
    BizflySelect,
    BizflyOption
  },
  props: {
    label: {
      type: String,
      default: 'null'
    },
    name: {
      type: String,
      default: ''
    },
    selection: {
      type: [String, Number],
      default: null
    },
    items: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default() {
        return []
      }
    },
    labelField: {
      type: String,
      default: 'label'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: '',
      activeLabel: ''
    }
  },
  mounted() {
    if (this.selection) {
      this.value = this.selection
    } else {
      this.activeLabel = 'inactive-label'
    }
  },
  methods: {
    handleOptionChanged() {
      this.$emit('change', this.value)
    }
  }
}
</script>
