<script>
import { Slider } from 'element-ui'

export default {
  name: 'BizflySlider',
  extends: Slider,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-slider'
    }
  }
}
</script>
