<script>
import { Dialog } from 'element-ui'

export default {
  name: 'BizflyDialog',
  extends: Dialog,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-dialog'
    },
    showClose: {
      type: Boolean,
      default: false
    }
  }
}
</script>
