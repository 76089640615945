<script>
import { Cascader } from 'element-ui'

export default {
  name: 'BizflyCascader',
  extends: Cascader,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-cascader'
    }
  }
}
</script>
