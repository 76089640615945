<script>
import { InputNumber } from 'element-ui'

export default {
  name: 'BizflyInputNumber',
  extends: InputNumber,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-input-number'
    }
  }
}
</script>
