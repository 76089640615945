<template>
  <bizfly-dropdown :placement="placement" class="bizfly-ui bizfly-menu" :trigger="trigger">
    <span class="el-dropdown-link">
      <slot />
    </span>
    <bizfly-dropdown-menu slot="dropdown" class="bizfly-menu--dropdown">
      <slot name="dropdown-menu" />
    </bizfly-dropdown-menu>
  </bizfly-dropdown>
</template>

<script>
import BizflyDropdown from '../Dropdown/index'
import BizflyDropdownMenu from '../DropdownMenu/index'

export default {
  name: 'BizflyMenu',
  components: {
    // Notifications,
    BizflyDropdown,
    BizflyDropdownMenu
  },
  props: {
    trigger: {
      type: String,
      default: 'click'
    },
    placement: {
      type: String,
      default: 'bottom-end'
    }
  }
}
</script>
