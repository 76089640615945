<template>
  <div class="bizfly-ui bizfly-support-quotas p-3">
    <div class="box-card">
      <p class="mb-2">
        Quota là phần giới hạn tài nguyên được sử dụng của bạn. Nếu có nhu cầu
        lớn xin vui lòng liên hệ bộ phận hỗ trợ để tăng quota.
      </p>
      <a class="bizfly-button el-button--dark m-2" href="tel:+842473028888">
        <i class="el-icon-phone-outline" /> +8424 7302 8888
      </a>
      <a
        class="bizfly-button el-button--dark m-2"
        :href="`https://${appDomain}/ticket/?subject=${encodeURI(
          'Đề nghị tăng quota'
        )}`"
        target="_blank m-2"
      >
        <i class="el-icon-s-ticket" /> Đề nghị tăng quota
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SupportQuotas',
  data() {
    return {
      appDomain: window.location.hostname
    }
  }
}
</script>
