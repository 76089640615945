<template>
  <bizfly-form ref="form" :model="form" class="auto-form">
    <div v-if="serviceName !== 'CDN'">
      <p>Quý khách có thể sử dụng tên miền được quản lý bởi Bizfly DNS hoặc tên miền đã được kết nối với Bizfly CDN.</p>
      <bizfly-form-item class="mb-4 mt-2">
        <bizfly-select v-model="form.service" class="seclect" :disabled="data.length === 1">
          <bizfly-option
            v-for="item in data"
            :key="item.index"
            :label="item.label"
            :value="item.value"
          />
        </bizfly-select>
      </bizfly-form-item>
      <p>1. Cert này có tác dụng để cấu hình https cho các website, ứng dụng,...</p>
      <p>2. Nếu Quý khách sử dụng SSL Certificate này với Bizfly Load Balancer, Certificate này sẽ được tự động Renew khi hết hạn.</p>
      <bizfly-form-item class="mt-2 mb-4" :error="error">
        <bizfly-row type="flex" justify="space-between" align="middle">
          <div class="label">
            <h3 v-if="data.length === 1 && data[0].value === 'cdn'">Tên miền đã chọn:</h3>
            <h3 v-else>
              <span class="text-danger">*</span> Chọn tên miền:
            </h3>
          </div>
          <bizfly-col :span="8">
            <bizfly-input
              v-if="form.service === 'dns'"
              v-model="form.subDomain"
              :disabled="disabled"
              @input="validate"
            />
          </bizfly-col>
          <b v-if="form.service === 'dns'" class="mx-1">.</b>
          <bizfly-select
            v-model="form.domain"
            class="seclect"
            :disabled="disabled || (data[0].value === 'cdn' && data[0].domains.length === 1)"
          >
            <bizfly-option
              v-for="item in data[0].domains"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            />
          </bizfly-select>
        </bizfly-row>
      </bizfly-form-item>
      <p class="text-right">
        <i>
          Quý khách có thể kết nối tên miền với Bizfly CDN
          <a :href="linkCdn" target="_blank">tại đây</a>
        </i>
      </p>
      <p class="text-right mb-4">
        <i>
          Quý khách có thể đăng ký quản lý tên miền với Bizfly DNS
          <a
            :href="linkDns"
            target="_blank"
          >tại đây</a>
        </i>
      </p>
      <p>Hệ thống tự động trả ra các thông tin như Tên, Certificate, Intermediates, Private Key và Passphare ngay sau khi Quý khách nhấn Tạo mới.</p>
    </div>
    <div v-else>
      <bizfly-form-item label="Cấu hình CNAME">
        <p class="text-danger mb-1">Hãy đảm bảo rằng tên miền CDN tùy chỉnh của Quý khách đã được trỏ đến tên miền CDN của hệ thống Bizfly Cloud trước khi Tạo mới chứng chỉ SSL miễn phí</p>
        <p class="note">Nếu chưa thực hiện, hãy làm theo hướng dẫn <a :href="docsConfigDNS" target="_blank" class="text-bold">tại đây</a></p>
      </bizfly-form-item>
      <bizfly-form-item label="Chứng chỉ SSL Let's Encrypt">
        <p class="note">Chứng chỉ được khởi tạo là chứng chỉ Let's Encrypt.</p>
        <p class="mb-1 note">Let's Encrypt được khởi tạo nhanh chóng, miễn phí và tự động. Nó giúp các website, ứng dụng có thể sử dụng giao thức https.</p>
        <p class="text-danger note">Lưu ý: Chứng chỉ SSL tự động này có thể sẽ không tương thích với một số thiết bị sử dụng hệ điều hành cũ. <a :href="encryptUrl" target="_blank" class="text-bold">Xem chi tiết</a></p>
      </bizfly-form-item>
    </div>
    <bizfly-button
      type="primary"
      class="btn-submit mt-4"
      :disabled="disabled"
      :loading="loading"
      @click="confirm"
    >Tạo mới chứng chỉ SSL miễn phí</bizfly-button>
  </bizfly-form>
</template>

<script>
import BizflyForm from '../../Form/index'
import BizflyFormItem from '../../FormItem/index'
import BizflySelect from '../../Select/index'
import BizflyOption from '../../Option/index'
import BizflyRow from '../../Row/index'
import BizflyCol from '../../Col/index'
import BizflyInput from '../../Input/index'
import BizflyButton from '../../Button/index'
export default {
  name: 'AutoForm',
  components: {
    BizflyForm,
    BizflyFormItem,
    BizflySelect,
    BizflyOption,
    BizflyRow,
    BizflyCol,
    BizflyInput,
    BizflyButton
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    serviceName: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    linkCdn: {
      type: String,
      required: true
    },
    linkDns: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        service: '',
        subDomain: '',
        domain: ''
      },
      docsConfigDNS: 'https://docs.bizflycloud.vn/cdn/howtos/dns_integration',
      encryptUrl: 'https://bizflycloud.vn/tin-tuc/hang-loat-thiet-bi-va-website-gap-su-co-truy-cap-do-loi-ssl-lets-encrypt-nguyen-nhan-va-cach-khac-phuc-20211022105335717.htm',
      error: ''
    }
  },
  watch: {
    data: 'loadData'
  },
  methods: {
    loadData() {
      this.form = {
        service: this.data[0].value,
        subDomain: '',
        domain: this.data[0].domains.length > 0 ? this.data[0].domains[0].value : ''
      }
      this.validate()
    },

    confirm() {
      if (this.validate()) {
        this.$emit('create', this.form)
        this.$refs.form.resetFields()
      }
    },

    validate() {
      if (this.form.service === 'dns') {
        if (this.form.subDomain.trim() === '' && this.data[0].domains.length > 0) {
          this.error = 'Tên không được để trống'
          return false
        } else if (this.data[0].domains.length === 0) {
          this.error = 'Vui lòng thêm tên miền tuỳ chỉnh trước khi cấu hình SSL tự động.'
          return true
        } else {
          this.error = ''
          return true
        }
      } else if (this.disabled) {
        this.error = 'Vui lòng thêm tên miền tuỳ chỉnh trước khi cấu hình SSL tự động.'
      } else {
        this.error = ''
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  color: #676767;
  font-weight: 400;
}
.auto-form {
  p {
    line-height : 18px;
  }
  .seclect {
    width: 100%;
  }
  .label {
    width: 300px;
  }
  b {
    font-size: 30px;
  }
  .btn-submit {
    width: 100%;
  }
}
</style>
