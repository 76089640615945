<template>
  <div class="upgrade-info">
    <table class="upgrade-info__table">
      <tr class="highlight">
        <td class="no-space">
          <div class="title mt-1">
            <strong>Số dư có thể chi cho dịch vụ:</strong>
            <b>{{ numberWithDelimiter(data.balance) }} VNĐ</b>
          </div>
          <div class="detail">
            <ul class="ml-4 mb-2">
              <li>
                <span>- Tài khoản chính</span>
                <span>{{ numberWithDelimiter(data.balances.primary) }} VNĐ</span>
              </li>
              <li>
                <span>- Tài khoản khuyến mãi chung</span>
                <span>{{ numberWithDelimiter(data.balances.promotion) }} VNĐ</span>
              </li>
              <li>
                <span>- Tài khoản khuyến mãi {{ data.balances.service.name }}</span>
                <span>{{ numberWithDelimiter(data.balances.service.value) }} VNĐ</span>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      <tr class="highlight">
        <td class="no-space">
          <div class="title mt-1">
            <strong>Số tiền cần thanh toán:</strong>
            <b>{{ numberWithDelimiter(currentBalance) }} VNĐ</b>
          </div>
          <div class="detail">
            <ul class="ml-4 mb-2">
              <li>
                <span>
                  - Hóa đơn nợ các dịch vụ
                  (
                  <a :href="`/billing/histories`" target="_blank">Chi tiết</a>)
                </span>
                <span>{{ numberWithDelimiter(data.suggestion.openInvoiceMoney) }} VNĐ</span>
              </li>
              <li>
                <span>- Số tiền duy trì dịch vụ {{ data.balances.service.name }} tháng {{ thisMonth }}</span>
                <span>{{ numberWithDelimiter(data.suggestion.thisMonthCharge) }} VNĐ</span>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      <tr v-if="showNextMonthCharge" class="highlight">
        <td v-if="data.suggestion.nextMonthCharge > 0" class="highlight-item">
          <bizfly-row type="flex" align="middle">
            <bizfly-checkbox
              v-model="checked"
              class="mr-2"
            >Nạp trước tiền dịch vụ {{ data.balances.service.name }} tạm tính tháng {{ nextMonth }}</bizfly-checkbox>
          </bizfly-row>
          <strong
            :class="checked ? '' : 'strike'"
          >{{ numberWithDelimiter(data.suggestion.nextMonthCharge) }} VNĐ</strong>
        </td>
      </tr>
      <tr v-if="totalCharge > 0" class="highlight">
        <td class="highlight-item">
          <strong>Tổng số tiền cần nạp thêm</strong>
          <b class="text-danger">{{ numberWithDelimiter(totalCharge) }} VNĐ</b>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import BizflyCheckbox from '../Checkbox'
import BizflyRow from '../Row'
import { numberWithDelimiter } from '../../lib/common'
export default {
  name: 'UpgradeInfo',
  components: { BizflyCheckbox, BizflyRow },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: true
    }
  },
  computed: {
    currentBalance() {
      return (this.checked && this.showNextMonthCharge)
        ? this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge + this.data.suggestion.nextMonthCharge
        : this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge
    },
    showNextMonthCharge() {
      return this.data.balance <= this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge + this.data.suggestion.nextMonthCharge
    },
    totalCharge() {
      return this.checked ? this.data.totalPayment : this.data.mandatoryPayment
    },
    thisMonth() {
      return new Date().getMonth() + 1
    },
    nextMonth() {
      return this.thisMonth % 12 + 1
    }
  },
  watch: {
    visible: 'loadData',
    checked: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    numberWithDelimiter,
    loadData() {
      if (this.totalCharge > 0) {
        this.$emit('changeButton', true)
      } else {
        this.$emit('changeButton', false)
      }
      this.$emit('changeCurrentCharge', this.totalCharge)
    }
  }
}
</script>

<style lang="scss">
.focusing:focus:not(:hover) {
  color: #303133 !important;
}
</style>
