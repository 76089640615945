<script>
import { Checkbox } from 'element-ui'

export default {
  name: 'BizflyCheckbox',
  extends: Checkbox,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-checkbox'
    }
  }
}
</script>
