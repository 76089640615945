<script>
import { FormItem } from 'element-ui'

export default {
  name: 'BizflyFormItem',
  extends: FormItem,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-form-item'
    }
  }
}
</script>
