<template>
  <bizfly-container class="bizfly-ui old-ui-error-page">
    <div class="content text-center">
      <img v-if="svg404" :src="svg404" class="image-error">
      <h1 class="text-center">Whoops!</h1>
      <h3 class="text-center">
        Trang của bạn truy cập không tồn tại hoặc đã bị gỡ bỏ,
        <br>vui lòng Quay lại hoặc thử đường link khác
      </h3>
      <bizfly-button type="text" @click="goBackHome">Quay về trang chủ</bizfly-button>
    </div>
  </bizfly-container>
</template>

<script>
import BizflyContainer from '../../Container/index'
import BizflyButton from '../../Button/index'
import svg404 from '../../../assets/images/404.svg'

export default {
  name: 'BizflyError404',
  components: {
    BizflyContainer,
    BizflyButton
  },
  data() {
    return {
      svg404
    }
  },
  methods: {
    goBackHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
