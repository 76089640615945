<template>
  <div class="bizfly-ui bizfly-steps">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[
        'bizfly-steps__step',
        currentStep === index ? 'active' : '',
        currentStep > index ? 'done' : ''
      ]"
    >
      <span class="bizfly-steps__step--text">
        <i v-if="currentStep > index" class="el-icon-check" />
        <span v-else>{{ index + 1 }}</span>
      </span>
      <span class="bizfly-steps__step--description">{{ step.description }}</span>
      <span class="bizfly-steps__step--line" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BizflyStep',
  props: {
    currentStep: Number,
    steps: Array
  }
}
</script>

<style lang="scss" scoped>
</style>
