<script>
import { Table } from 'element-ui'

export default {
  name: 'BizflyTable',
  extends: Table,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-table'
    }
  }
}
</script>
