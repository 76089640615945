<script>
import { Upload } from 'element-ui'

export default {
  name: 'BizflyUpload',
  extends: Upload,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-upload'
    }
  }
}
</script>
