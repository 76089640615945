<script>
import { Tabs } from 'element-ui'

export default {
  name: 'BizflyTabs',
  extends: Tabs,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-tabs'
    }
  }
}
</script>
