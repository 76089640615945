<template>
  <div class="bizfly-skeleton-loading">
    <div v-if="height > 0" class="content">
      <div
        v-if="iconVisible"
        class="icon"
        :style="{ 'min-width': iconSize + 'px', height: iconSize + 'px' }"
      />
      <div
        class="item w-100"
        :style="{ height: height + 'px' }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoading',
  props: {
    height: {
      type: Number,
      default: 50
    },
    iconVisible: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: Number,
      default: 32
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.bizfly-skeleton-loading {
  .icon,
  .item {
    background: #f2f2f2;
  }
  .content {
    display: flex;
    align-items: end;
    .icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      animation: pulse-bg 1s infinite;
      border-radius: 5px;
    }
    .item {
      height: 22px;
      width: 100%;
      margin: 0;
      animation: pulse-bg 1s infinite;
    }
  }
}
@keyframes pulse-bg {
  0% {
    background-color: #f2f2f2;
  }
  50% {
    background-color: #f9f9f9;
  }
  100% {
    background-color: #f2f2f2;
  }
}
</style>
