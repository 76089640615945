<template>
  <bizfly-dialog
    class="bizfly-ui bizfly-suggestion-dialog"
    :title="title"
    :close-on-click-modal="false"
    :visible="visible"
    width="1024px"
    @close="$emit('close')"
  >
    <bizfly-row>
      <bizfly-col :span="11" class="resources">
        <h3>Thông tin dịch vụ: {{ service.name }}</h3>
        <h4>{{ service.infomation }}:</h4>
        <bizfly-collapse v-if="service.resources.length" class="info">
          <bizfly-collapse-item
            v-for="item in service.resources"
            :key="item.index"
            :class="item.items.length || (item.childrenResources && item.childrenResources.length) ? '' : 'hide-arrow'"
          >
            <template slot="title">
              <div class="title-detail pr-2">
                <bizfly-tooltip placement="top">
                  <strong slot="content">{{ item.name }}</strong>
                  <strong>{{ item.name }}</strong>
                </bizfly-tooltip>
                <b>{{ item.value }}</b>
              </div>
            </template>
            <div v-if="(item.items && item.items.length) || (item.childrenResources && item.childrenResources.length)" class="detail">
              <ul class="ml-3 mb-2 mt-2">
                <div v-if="item.items && item.items.length">
                  <li v-for="info in item.items" :key="info.id" class="pr-2 mb-1">
                    <div>
                      <div v-if="service.isResize && info.description">
                        <p>- {{ info.name }}</p>
                        <small class="pl-2">{{ info.description }}</small>
                      </div>
                      <span v-else>- {{ info.name }}</span>
                      <small v-if="info.desc" class="pl-2">{{ info.desc }}</small>
                    </div>
                    <b>{{ info.value }}</b>
                  </li>
                </div>

                <!-- Children resources -->
                <div v-if="item.childrenResources">
                  <div v-for="(childrenResource, index) in item.childrenResources" :key="index" class="pr-2 mb-1">
                    <div class="children-resource">
                      <bizfly-row
                        type="flex"
                        justify="space-between"
                        class="children-resource--title"
                      >
                        <bizfly-row
                          type="flex"
                          justify="start"
                          align="middle"
                          class="title-tooltip"
                        >
                          <i class="el-icon-arrow-down transition-detail mr-1" />
                          <bizfly-tooltip placement="top">
                            <strong slot="content">
                              {{ childrenResource.name }}
                            </strong>
                            <strong>{{ childrenResource.name }}</strong>
                          </bizfly-tooltip>
                        </bizfly-row>
                        <div>
                          <b>{{ childrenResource.value }}</b>
                        </div>
                      </bizfly-row>
                      <div v-if="childrenResource.items">
                        <div
                          v-if="childrenResource.items.length"
                          class="children-resource--body"
                        >
                          <ul class="ml-3 mb-1 mt-1">
                            <li v-for="(itemResource, resourceIndex) in childrenResource.items" :key="resourceIndex">
                              <bizfly-row>
                                <div v-if="service.isResize && itemResource.description">
                                  <p>- {{ itemResource.name }}</p>
                                  <small class="pl-2">
                                    {{ itemResource.description }}
                                  </small>
                                </div>
                                <span v-else>- {{ itemResource.name }}</span>
                                <small v-if="itemResource.desc" class="pl-2">
                                  {{ itemResource.desc }}
                                </small>
                              </bizfly-row>
                              <b>{{ itemResource.value }}</b>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </bizfly-collapse-item>
        </bizfly-collapse>
        <bizfly-alert v-else type="warning" :closable="false" class="my-2">
          {{ service.notify }}
        </bizfly-alert>
        <bizfly-row
          v-if="service.billingPlan === 'on_demand'"
          type="flex"
          justify="space-between"
          align="top"
          class="my-2 pl-1 pr-2 total"
        >
          <h4>Tổng chi phí dự kiến</h4>
          <b>{{ service.monthlyFee }}</b>
        </bizfly-row>
        <bizfly-row
          v-else-if="service.monthlyFee && !service.isResize"
          type="flex"
          justify="space-between"
          align="top"
          class="my-2 pl-1 pr-2 total"
        >
          <div>
            <h4 v-if="service.name === 'Cloud Database'">
              Tổng chi phí dự kiến:
            </h4>
            <h4 v-else>Tổng chi phí hàng tháng:</h4>
            <small v-if="service.name === 'Bizfly Kubernetes Engine'">
              Bao gồm: Cluster + Node pool mặc định
            </small>
            <small v-if="service.name === 'Auto Scaling Group'">
              Tính theo số lượng máy chủ mặc định
            </small>
          </div>
          <b class="text-bold">{{ service.monthlyFee }}</b>
        </bizfly-row>
        <bizfly-row
          v-if="service.isAutoScale"
          type="flex"
          justify="space-between"
          align="top"
          class="my-2 pl-1 pr-2 total"
        >
          <div>
            <h4>Tổng chi phí Min-Max hàng tháng:</h4>
            <small>{{ service.autoScale.description }}</small>
          </div>
          <b class="text-bold">{{ service.autoScale.chargeMinMax }}</b>
        </bizfly-row>
        <bizfly-row v-if="service.isResize" class="my-3 pl-1 pr-2 total">
          <bizfly-row
            v-if="service.name === 'Bizfly Kubernetes Engine'"
            type="flex"
            justify="space-between"
            align="top"
          >
            <div>
              <h4>Chi phí chênh lệch:</h4>
              <small>Tính theo chi phí hàng tháng của Node pool mặc định</small>
            </div>
            <b class="text-bold">
              {{ numberWithDelimiter(service.costDifference) }} VNĐ
            </b>
          </bizfly-row>
          <bizfly-row v-if="service.name === 'Cloud Database'" type="flex" justify="space-between" align="top">
            <h4>Tổng chi phí dự kiến:</h4>
            <b
              class="text-bold"
            >{{ service.monthlyFee }}</b>
          </bizfly-row>
          <bizfly-row v-else type="flex" justify="space-between" align="top">
            <h4>Chi phí chênh lệch:</h4>
            <b
              class="text-bold"
            >{{ numberWithDelimiter(service.costDifference) }} VNĐ</b>
          </bizfly-row>
        </bizfly-row>

        <bizfly-row
          type="flex"
          justify="space-between"
          align="middle"
          class="mt-2 pl-1 pr-2 text-danger"
        >
          <div v-if="service.billingPlan === 'on_demand' || service.name === 'Cloud Database'" class="charge-this-month">
            <h4 class="text-danger">Chi phí duy trì tài nguyên trong 72h tới:</h4>
          </div>
          <div v-else class="charge-this-month">
            <h4 class="text-danger">Thanh toán hôm nay:</h4>
            <small v-if="today !== lastDayOfMonth" class="text-danger">
              (Từ ngày {{ today }} tới {{ lastDayOfMonth }})
            </small>
            <small v-else class="text-danger">
              (đến hết ngày {{ lastDayOfMonth }})
            </small>
          </div>
          <b>{{ numberWithDelimiter(service.billingPlan === 'on_demand' || service.name === 'Cloud Database' ? data.suggestion.newCost : data.suggestion.thisMonthCharge) }} VNĐ</b>
        </bizfly-row>
        <p v-if="service.name === 'Bizfly Kubernetes Engine'" class="text-danger mb-4 note">
          * Trong quá trình sử dụng, chi phí các tài nguyên phát sinh như
          Volume, Load Balancer (nếu có) sẽ được bổ sung vào chi tiết hóa đơn sử
          dụng.
        </p>
        <p v-if="service.name === 'Cloud Database'" class="text-danger mb-4 note">
          * Trong quá trình sử dụng, chi phí các tài nguyên phát sinh như
          Dung lượng lưu trữ backup (nếu có) sẽ được bổ sung vào chi tiết hóa đơn sử
          dụng.
        </p>
        <small v-else-if="service.billingPlan === 'on_demand'" class="text-note mt-3">
          *Khi tắt máy chủ, chi phí duy trì máy chủ là {{ numberWithDelimiter(data.suggestion.newCost) }} VNĐ/giờ.
        </small>
      </bizfly-col>
      <bizfly-col :span="13" class="info-payment">
        <h3>Thông tin thanh toán</h3>
        <b
          class="mb-2 ml-2"
        >Loại tài khoản:
          {{ data.accountType === "pre-paid" ? "Trả trước" : "Trả sau" }}</b>
        <table class="upgrade-info__table">
          <tr class="highlight">
            <td class="no-space">
              <div class="title-suggestion mt-1">
                <strong>Số dư có thể chi cho dịch vụ:</strong>
                <b>{{ numberWithDelimiter(data.balance) }} VNĐ</b>
              </div>
              <div class="detail">
                <ul class="ml-4 mb-2">
                  <li>
                    <span>- Tài khoản chính</span>
                    <span>{{ numberWithDelimiter(data.balances.primary) }}VNĐ</span>
                  </li>
                  <li>
                    <span>- Tài khoản khuyến mãi chung</span>
                    <span>{{ numberWithDelimiter(data.balances.promotion) }} VNĐ</span>
                  </li>
                  <li>
                    <span>- Tài khoản khuyến mãi
                      {{ data.balances.service.name }}</span>
                    <span>{{ numberWithDelimiter(data.balances.service.value) }} VNĐ</span>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr class="highlight">
            <td class="no-space">
              <div class="title-suggestion mt-1">
                <strong>{{ service.billingPlan === 'on_demand' ? 'Số dư tối thiểu yêu cầu' : 'Số tiền cần thanh toán' }}:</strong>
                <b>
                  {{ numberWithDelimiter(
                    service.billingPlan === 'on_demand' ? data.suggestion.totalCost :
                    service.name === 'Cloud Database' ? data.suggestion.totalFee : currentBalance)
                  }} VNĐ
                </b>
              </div>
              <div class="detail">
                <ul class="ml-4 mb-2">
                  <!--  -->
                  <li>
                    <span>
                      - Hóa đơn nợ các dịch vụ (
                      <a
                        :href="`/billing/histories`"
                        target="_blank"
                      >Chi tiết</a>)
                    </span>
                    <span>{{ numberWithDelimiter(data.suggestion.openInvoiceMoney) }} VNĐ</span>
                  </li>

                  <!--  -->
                  <div v-if="service.billingPlan === 'on_demand' || service.name === 'Cloud Database'">
                    <li>
                      <span>- Số dư tối thiểu để duy trì tài nguyên hiện có hôm nay</span>
                      <span>{{ numberWithDelimiter(data.suggestion.maintainCost) }} VNĐ</span>
                    </li>
                    <li>
                      <span>- Số dư tối thiểu để duy trì tài nguyên đã chọn</span>
                      <span>{{ numberWithDelimiter(data.suggestion.newCost) }} VNĐ</span>
                    </li>
                  </div>
                  <div v-else>
                    <li v-if="service.isResize && data.accountType === 'pre-paid'">
                      <span>- Chi phí thay đổi tài nguyên</span>
                      <span>{{ numberWithDelimiter(data.suggestion.thisMonthCharge) }} VNĐ</span>
                    </li>
                    <li v-else>
                      <span>- Số tiền duy trì dịch vụ
                        {{ data.balances.service.name }} tháng
                        {{ thisMonth }}</span>
                      <span>{{ numberWithDelimiter(data.suggestion.thisMonthCharge) }} VNĐ</span>
                    </li>
                  </div>

                  <!-- When change config -->
                  <li v-if="service.isResize && service.billingPlan === 'saving_plan'">
                    <span>
                      - Số tiền hoàn lại gói cước cấu hình cũ
                    </span>
                    <span>{{ numberWithDelimiter(data.suggestion.refund_charge) }} VNĐ</span>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="showNextMonthCharge" class="highlight">
            <td v-if="data.suggestion.nextMonthCharge > 0" class="highlight-item">
              <el-row type="flex" align="middle">
                <bizfly-checkbox
                  v-model="checked"
                  class="mr-2"
                  @change="handleChangeNextMonthCharge"
                >
                  Nạp trước tiền dịch vụ {{ data.balances.service.name }} tháng
                  {{ nextMonth }}
                </bizfly-checkbox>
              </el-row>
              <strong :class="checked ? '' : 'strike'">{{ numberWithDelimiter(data.suggestion.nextMonthCharge) }} VNĐ</strong>
            </td>
          </tr>
          <tr v-if="totalCharge > 0" class="highlight">
            <td class="highlight-item">
              <strong>Tổng số tiền cần nạp thêm</strong>
              <b class="text-danger">{{ numberWithDelimiter(totalCharge) }} VNĐ</b>
            </td>
          </tr>
        </table>
      </bizfly-col>
    </bizfly-row>
    <bizfly-row slot="footer" type="flex" justify="end" align="middle">
      <bizfly-button
        type="default"
        :disabled="loading"
        @click="$emit('close')"
      >
        Hủy bỏ
      </bizfly-button>
      <a
        v-if="totalCharge > 0 && !whitelist && data.accountType === 'pre-paid'"
        class="el-button el-button--primary bizfly-ui bizfly-button"
        :href="`/billing?price=${totalCharge}`"
      >
        Nạp tiền
      </a>
      <bizfly-button
        v-else
        type="primary"
        :loading="loading"
        @click="$emit('confirm')"
      >
        Xác nhận
      </bizfly-button>
    </bizfly-row>
  </bizfly-dialog>
</template>

<script>
import { numberWithDelimiter } from '../../lib/common'

import BizflyRow from '../Row/index'
import BizflyCol from '../Col/index'
import BizflyCheckbox from '../Checkbox'
import BizflyButton from '../Button/index'
import BizflyDialog from '../Dialog/index'
import BizflyCollapse from '../Collapse/index'
import BizflyTooltip from '../Tooltip/index'
import BizflyCollapseItem from '../CollapseItem/index'
import BizflyAlert from '../Alert/index'
export default {
  name: 'SuggestionDialog',
  components: {
    BizflyAlert,
    BizflyRow,
    BizflyCol,
    BizflyCheckbox,
    BizflyButton,
    BizflyDialog,
    BizflyCollapse,
    BizflyCollapseItem,
    BizflyTooltip
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Xác nhận nâng cấp tài khoản'
    },
    visible: {
      default: true,
      type: Boolean
    },
    whitelist: {
      type: Boolean,
      required: false
    },
    data: {
      require: true,
      type: Object
    },
    service: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checked: true
    }
  },
  computed: {
    currentBalance() {
      if (this.checked && this.showNextMonthCharge) {
        if (this.service && this.service.isResize && this.service.billingPlan === 'saving_plan') {
          return this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge + this.data.suggestion.nextMonthCharge - this.data.suggestion.refund_charge
        }
        return this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge + this.data.suggestion.nextMonthCharge
      } else {
        if (this.service && this.service.isResize && this.service.billingPlan === 'saving_plan') {
          return this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge - this.data.suggestion.refund_charge
        }
        return this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge
      }
    },
    showNextMonthCharge() {
      return this.data.balance <= this.data.suggestion.openInvoiceMoney + this.data.suggestion.thisMonthCharge + this.data.suggestion.nextMonthCharge
    },
    totalCharge() {
      return this.checked ? this.data.totalPayment : this.data.mandatoryPayment
    },
    today() {
      const date = new Date()
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    },
    lastDayOfMonth() {
      const date = new Date()
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      return (lastDay.getDate()) + '/' + (lastDay.getMonth() + 1) + '/' + lastDay.getFullYear()
    },
    thisMonth() {
      return new Date().getMonth() + 1
    },
    nextMonth() {
      return this.thisMonth % 12 + 1
    }
  },
  methods: {
    numberWithDelimiter,

    handleChangeNextMonthCharge() {
      this.$emit('changeNextMonthCharge', this.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.children-resource{
  &--title{
    cursor: pointer;
    .arrow-icon-right{
      transform: rotate(-90deg);
    }
    .arrow-icon-down{
      transform: rotate(0deg);
    }
    .title-tooltip{
      width: 70%;
      font-size: 14px;
      .el-tooltip{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .transition-detail{
    transition: 0.2s ease-out;
  }
}
</style>
