<template>
  <div class="bizfly-ui bizfly-release-notes">
    <div class="release-notes-btn" :class="onLeft ? 'on-left' : 'on-right'">
      <bizfly-support-dialog :url="releaseUrl" />
    </div>
  </div>
</template>

<script>
import BizflySupportDialog from '../SupportDialog/index'

export default {
  name: 'BizflyFeedback',
  components: {
    BizflySupportDialog
  },
  props: {
    onLeft: {
      type: Boolean,
      default: true
    },
    service: {
      type: String
    }
  },
  data() {
    return {
      url: 'https://docs.bizflycloud.vn/release_notes'
    }
  },
  computed: {
    releaseUrl() {
      if (this.service) {
        return `${this.url}?service=${this.service}`
      }
      return this.url
    }
  },
  methods: {}
}
</script>
