<template>
  <bizfly-container class="bizfly-ui old-ui-error-page">
    <div class="content text-center">
      <img v-if="svg5xx" :src="svg5xx" class="image-error">
      <h1 class="text-center">We'll be back!!</h1>
      <h3
        class="text-center"
      >Dashboard đang trong quá trình bảo trì để nâng cấp hệ thống. Các dịch vụ của bạn vẫn hoạt động bình thường.</h3>
      <h3 class="text-center">Nếu cần hỗ trợ, vui lòng gửi thông tin cho chúng tôi:</h3>
      <report-form :user="user" />
      <bizfly-button type="text" @click="goBackHome">Quay về trang chủ</bizfly-button>
    </div>
  </bizfly-container>
</template>

<script>
import ReportForm from '../form'
import BizflyContainer from '../../Container/index'
import BizflyButton from '../../Button/index'
import svg5xx from '../../../assets/images/5xx.svg'

export default {
  name: 'BizflyError502',
  components: {
    ReportForm,
    BizflyContainer,
    BizflyButton
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      svg5xx
    }
  },
  methods: {
    goBackHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
