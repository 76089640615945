<script>
import { Pagination } from 'element-ui'

export default {
  name: 'BizflyPagination',
  extends: Pagination,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-pagination'
    }
  }
}
</script>
