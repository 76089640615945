<script>
import { DropdownItem } from 'element-ui'

export default {
  name: 'BizflyDropdownItem',
  extends: DropdownItem,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-dropdown-item'
    }
  }
}
</script>
