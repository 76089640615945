<template>
  <li id="switch-region" class="bizfly-header--services__service">
    <el-dropdown placement="bottom-start" trigger="click">
      <span class="bizfly-user-email dropdown"><b class="mr-1">Region:</b> {{ regionName }}</span>
      <el-dropdown-menu slot="dropdown" class>
        <div v-if="isGlobal && currentService" class="select-region-notice">
          <strong>{{ currentService.name }}</strong>
          không cần chọn vùng
        </div>
        <el-dropdown-item
          v-for="region in Object.keys(regions)"
          :key="region"
          :disabled="!availabilityRegions.includes(region) || currentRegion === region || isGlobal"
          :divided="isGlobal"
          @click.native="switchRegion(region)"
        >
          {{ regions[region] }}
          <span v-if="!availabilityRegions.includes(region)">(Comming Soon)</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </li>
</template>

<script>

export default {
  name: 'SwitchRegion',
  props: {
    userData: Object,
    listServices: Object,
    environment: String,
    regions: {
      type: Object,
      default: function() {
        return {}
      }
    },
    currentEnvironment: {
      type: String,
      default: 'manage'
    },
    currentRegion: {
      type: String,
      default: 'hn'
    }
  },
  data() {
    return {
      window,
      ignoredSubDomains: [
        'bizfly-ui',
        'local'
      ],
      whitelistServices: [
        {
          createUrl: '#',
          isGlobal: true,
          slug: 'billing',
          name: 'Billing',
          url: '/billing'
        },
        {
          createUrl: '#',
          isGlobal: false,
          slug: 'dashboard',
          name: 'Dashboard',
          url: '/dashboard',
          'availabilityRegions': [
            'hn', 'hcm'
          ],
          'hide': true
        }
      ]
    }
  },
  computed: {
    flattenListServices() {
      let services = Object.values(this.listServices).map(service => service.list)
      services = [].concat(...services)
        .filter(service => !service.hide && service.url !== '#') // Flatten array
      return [...services, ...this.whitelistServices]
    },
    currentService() {
      const url = window.location.href
      // const url = 'https://manage.bizflycloud.vn//iaas-cloud/volumes/create'
      const slug = url.split('/')[3]
      const service = this.flattenListServices.find(service => {
        return service.slug === slug
      })
      return service || {
        'id': 1,
        'name': 'Cloud Server',
        'url': '/iaas-cloud/servers',
        'slug': 'iaas-cloud',
        'createUrl': '/iaas-cloud/servers/create',
        'icon': 'bizfly-icon bizfly-icon__server',
        'describe': 'Khởi tạo Cloud Server',
        'isGlobal': false,
        'availabilityRegions': [
          'hn', 'hcm'
        ]
      }
    },
    availabilityRegions() {
      return this.currentService.availabilityRegions || Object.keys(this.regions)
    },
    // currentRegion() {
    //   if (this.ignoredSubDomains.includes(window.location.hostname.split('.')[0])) {
    //     return 'hn'
    //   }
    //   return window.location.hostname.split('.')[0].split('-')[0]
    // },
    isGlobal() {
      return this.currentService ? this.currentService.isGlobal : true
    },
    regionName() {
      if (this.isGlobal) {
        return 'Global'
      }
      return this.regions[this.currentRegion]
    }
  },
  // mounted() {
  //   window.addEventListener('load', this.initTooltip())
  //   console.log('current region', this.currentRegion, 'current enviroment', this.currentEnvironment, 'flattenListServices', this.flattenListServices)
  // },
  methods: {
    switchRegion(region) {
      if (region !== this.currentRegion) {
        let subDomain = `${region}-${this.currentEnvironment}.manage`
        if (this.currentEnvironment === 'manage') {
          subDomain = `${region}.${this.currentEnvironment}`
        } else if (window.location.host.includes('staging2')) {
          subDomain = `${region}-staging2`
        }
        const data = { last_region: this.currentRegion.toUpperCase() }
        fetch(`https://${this.currentEnvironment}.bizflycloud.vn/account/api/users`, {
          method: 'PUT',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then((json) => {})
        setTimeout(() => {
          window.location.href = `https://${subDomain}.bizflycloud.vn${this.currentService.url}`
        }, 500)
      }
    },

    initTooltip() {
      try {
        window.bizfly_cloud_gen_extension.initToolTip('switch-region')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
.select-region-notice {
  padding: 0 20px;
}
</style>
