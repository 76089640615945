<template>
  <div class="skeleton-loading">
    <div class="heading">
      <div class="icon" />
      <div class="text" />
    </div>
    <div class="content">
      <div
        v-for="number in 5"
        :key="number"
        class="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoading'
}
</script>

<style lang="scss">
  .skeleton-loading {
    .icon, .text, .item {
      background: #ddd;
    }
    .heading {
      display: flex;
      align-items: center;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        animation: pulse-bg 1s infinite;
        border-radius: 5px;
      }
      .text {
        width: 130px;
        height: 18px;
        animation: pulse-bg 1s infinite;
      }
    }
    .content {
      margin-top: 10px;
      .item {
        height: 14px;
        width: 200px;
        margin: 6px 0;
        -webkit-animation: pulse-bg 1s infinite;
        animation: pulse-bg 1s infinite;
      }
    }
  }
  @keyframes pulse-bg {
    0% { background-color: #ddd; }
    50% { background-color: #d0d0d0; }
    100% { background-color: #ddd; }
  }
</style>
