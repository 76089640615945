<script>
import { Submenu } from 'element-ui'

export default {
  name: 'BizflySubmenu',
  extends: Submenu,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-submenu'
    }
  }
}
</script>
