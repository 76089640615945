<template>
  <div class="bizfly-ui bizfly-support-dialog">
    <a v-if="text" href="javascript:;" @click="handleShowPopup">{{ text }}</a>
    <i v-else class="el-icon-warning" @click="handleShowPopup" />
    <div v-if="isShow" class="container-popup-support">
      <div class="vcc-support-dialog">
        <div class="vcc-support-header" @click="calOffset">
          <div class="ghp-header-row">
            <h1 id="header-title" class="ghp-header-title">Hỗ trợ</h1>
            <button
              class="ghp-header-closeIcon ghp-closeIcon"
              @click="isShow = false"
            />
          </div>
        </div>
        <div class="popupIframeWrapper">
          <iframe
            :src="url"
            class="popupIframe"
            frameborder="0"
            scrolling="' + scroll + '"
          />
        </div>
        <div id="btn-newtab">
          <a
            :href="url"
            target="_blank"
            @click="isShow = false"
          >Mở ở cửa sổ lớn</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SupportDialog',
  props: {
    url: String,
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShow: false,
      x: 0,
      y: 0,
      pointer: 0
    }
  },
  methods: {
    handleShowPopup() {
      this.isShow = true
      this.$nextTick(() => {
        this.handleDrag()
      })
    },

    calOffset() {
      if (document.querySelector('.vcc-support-dialog')) {
        this.x = document.querySelector('.vcc-support-dialog').offsetLeft
        this.y = document.querySelector('.vcc-support-dialog').offsetTop
      }
    },

    handleDrag() {
      var dragItem = document.querySelector('.vcc-support-dialog')
      var container = document.querySelector('.container-popup-support')

      var active = false
      var currentX
      var currentY
      var initialX
      var initialY
      var xOffset = 0
      var yOffset = 0

      container.addEventListener('touchstart', dragStart, false)
      container.addEventListener('touchend', dragEnd, false)
      container.addEventListener('touchmove', drag, false)

      container.addEventListener('mousedown', dragStart, false)
      container.addEventListener('mouseup', dragEnd, false)
      container.addEventListener('mousemove', drag, false)

      container.addEventListener('mousedown', setPointerTrue, false)
      container.addEventListener('mouseup', setPointerFalse, false)

      function setPointerTrue(e) {
        if (!this.pointer) this.pointer = 1
      }

      function setPointerFalse(e) {
        if (this.pointer) this.pointer = 0
      }

      function dragStart(e) {
        if (e.type === 'touchstart') {
          initialX = e.touches[0].clientX - xOffset
          initialY = e.touches[0].clientY - yOffset
        } else {
          initialX = e.clientX - xOffset
          initialY = e.clientY - yOffset
        }
        if (e.target === document.querySelector('.ghp-header-row') || e.target === document.querySelector('#header-title')) {
          active = true
        }
      }

      function dragEnd(e) {
        initialX = currentX
        initialY = currentY

        this.x = e.clientX - e.offsetX
        this.y = e.clientY - e.offsetY
        active = false
      }

      function drag(e) {
        if (active) {
          e.preventDefault()

          if (e.type === 'touchmove') {
            currentX = e.touches[0].clientX - initialX
            currentY = e.touches[0].clientY - initialY
          } else {
            currentX = e.clientX - initialX
            currentY = e.clientY - initialY
          }

          xOffset = currentX
          yOffset = currentY

          setTranslate(currentX, currentY, dragItem)
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)'
      }

      document.addEventListener('mousemove', function(ev) {
        if (!document.querySelector('#container-popup-support')) return
        if (this.pointer !== 1) {
          const popup = document.getElementById('container-popup-support')
          if (this.x <= ev.clientX && ev.clientX <= this.x + 380 && this.y <= ev.clientY && ev.clientY <= this.y + document.querySelector('.vcc-support-dialog').offsetHeight) {
            if (popup.style['pointer-events'] !== 'initial') {
              popup.style['pointer-events'] = 'initial'
            }
          } else {
            if (popup.style['pointer-events'] !== 'none') {
              popup.style['pointer-events'] = 'none'
            }
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scope>
</style>
