<template>
  <div
    class="bizfly-ribbon bottom-left sticky shadow"
    :style="{'background': color}"
  >
    {{ label }}
  </div>
</template>
<script>
export default {
  name: 'BizflyRibbon',
  props: {
    label: {
      type: String,
      default: 'Open Beta'
    },
    color: {
      type: String,
      default: '#e43'
    }
  }
}
</script>
