<template>
  <div class="bizfly-ui bizfly-loader-page">
    <div class="spinner-box">
      <div class="circle-border">
        <div class="circle-core" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BizflyLoaderPage'
}
</script>
