<template>
  <div class="bizfly-header__mega-menu__container__content">
    <bizfly-row :gutter="20" style="margin-left: 0; margin-right: 0;">
      <bizfly-col :span="24">
        <p
          class="guide"
        >Quý khách có thể chọn thiết lập từ 1 tới 4 dịch vụ yêu thích lên trên menu bằng cách kéo thả dịch vụ từ danh sách phía dưới lên phía trái hình ngôi sao. Kéo để sắp xếp lại vị trí giữa các dịch vụ yêu thích.</p>
      </bizfly-col>
      <draggable
        v-if="showDeleteZone"
        :sort="false"
        :group="{ name: 'dragDrop'}"
        class="delete-zone"
        @start="drag = true"
        @end="drag = false"
      >
        <bizfly-col :offset="2" :span="20" class="display-text">Kéo vào đây để xóa</bizfly-col>
      </draggable>

      <draggable
        v-model="filteredListServices"
        :disabled="disableDnD"
        :sort="false"
        :group="{ name: 'dragDrop', put: false }"
        class="option-services"
        @start="drag = true"
        @end="drag = false"
      >
        <bizfly-col
          v-for="(service, index) in filteredListServices"
          :key="index"
          class="bizfly-header__mega-menu__container__content__column__group--list"
          :span="8"
        >
          <a
            :key="index"
            href="javascript:;"
            class="dropdown-item draggable-item"
            :class="[
              { 'beta': service.isBeta },
              { 'disable-dnd': disableDnD },
            ]"
          >
            <i class="bizfly-icons" :class="service.icon" />
            <div class="bizfly-header__mega-menu__container__content__column__group--list__text">
              <span>{{ service.name }}</span>
              <p>{{ service.describe }}</p>
            </div>
          </a>
        </bizfly-col>
      </draggable>
    </bizfly-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import BizflyRow from '../Row/index'
import BizflyCol from '../Col/index'

export default {
  name: 'ListService',
  components: {
    draggable,
    BizflyRow,
    BizflyCol
  },
  props: {
    listServices: {
      type: Object,
      required: true
    },
    showDeleteZone: {
      type: Boolean,
      default: false
    },
    pinnedMenu: {
      type: Array
    }
  },
  data() {
    return {
      filteredListServices: []
    }
  },
  computed: {
    disableDnD() {
      return this.pinnedMenu.length >= 4
    }
  },
  watch: {
    pinnedMenu: 'loadListService'
  },
  beforeMount() {
    this.loadListService()
  },
  methods: {
    loadListService() {
      const listServiceNamePinned = this.pinnedMenu.map(service => service.name)
      let services = Object.values(this.listServices).map(service => service.list)
      services = [].concat(...services)
        .filter(service => !service.hide && service.url !== '#' && !listServiceNamePinned.includes(service.name)) // Flatten array
      this.filteredListServices = services
    }
  }
}
</script>
