<template>
  <div class="bizfly-ui bizfly-feedback">
    <div
      id="public-btn-feedback"
      class="help-btn"
      :style="buttonPositionStyles"
      :class="onLeft ? 'on-left' : 'on-right'"
    >
      <bizfly-dropdown trigger="click">
        <span class="el-dropdown-link">
          <bizfly-icon name="question-circle" :scale="1.8" class="trigger-button" />
        </span>
        <bizfly-dropdown-menu slot="dropdown">
          <a href="https://manage.bizflycloud.vn/ticket/" target="_blank">
            <bizfly-dropdown-item>
              <bizfly-icon name="exclamation-triangle" class="mr-2" />Báo lỗi & Phản hồi
            </bizfly-dropdown-item>
          </a>
          <!-- <bizfly-dropdown-item @click.native="open">
            <bizfly-icon name="exclamation-triangle" class="mr-2" />Báo lỗi & Phản hồi
          </bizfly-dropdown-item> -->
          <bizfly-dropdown-item @click.native="goToSupportCenter">
            <bizfly-icon name="info-circle" class="mr-2" />Tài liệu hỗ trợ
          </bizfly-dropdown-item>
        </bizfly-dropdown-menu>
      </bizfly-dropdown>
    </div>
    <div v-if="state.isOpen" ref="feedbackContainer" class="feedback-container">
      <div
        v-if="!state.canDraw"
        class="bizfly-feedback-form-container"
        data-html2canvas-ignore="true"
      >
        <bizfly-form ref="feedbackForm" :model="feedbackForm">
          <div class="bizfly-feedback-header">
            <h1>Gửi phản hồi</h1>
          </div>
          <bizfly-form-item
            prop="message"
            :rules="[
              {
                required: true,
                message: 'Vui lòng nhập mô tả',
                trigger: ['blur', 'change']
              }
            ]"
          >
            <bizfly-input
              v-model="feedbackForm.message"
              type="textarea"
              placeholder="Mô tả vấn đề bạn đang gặp phải hoặc ý kiến đóng góp."
              :disabled="state.sending"
            />
          </bizfly-form-item>
          <bizfly-checkbox
            v-model="state.includeScreenshot"
            class="ml-3 mt-2 mb-2"
            :disabled="state.sending"
          >Gửi kèm ảnh chụp màn hình</bizfly-checkbox>
          <div
            v-show="state.includeScreenshot"
            ref="screenshotContainer"
            v-loading="loadingScreenshot"
            element-loading-text="Đang chụp ảnh màn hình..."
            class="bizfly-feedback-screenshot"
            :class="!loadingScreenshot ? 'ready' : 'loading'"
          >
            <canvas width="1133" height="719" style="width: 1133px; height: 719px;" />
          </div>
          <div class="bizfly-feedback-actions">
            <bizfly-button size="small" :disabled="state.sending" @click="close">Hủy bỏ</bizfly-button>
            <bizfly-button
              type="primary"
              size="small"
              :loading="state.sending"
              @click="send"
            >Xác nhận</bizfly-button>
          </div>
        </bizfly-form>
      </div>
      <vue-draggable-resizable
        v-show="state.canDraw"
        ref="drawOptions"
        class-name="bizfly-feedback-draw-options"
        :resizable="false"
        :x="10"
        :y="10"
        :z="30"
        :w="294"
        :h="38"
      >
        <div class="dragger">
          <i class="el-icon-rank" />
        </div>
        <bizfly-radio-group v-model="state.highlight">
          <bizfly-radio-button :label="true">Nổi bật</bizfly-radio-button>
          <bizfly-radio-button :label="false">Bôi đen</bizfly-radio-button>
        </bizfly-radio-group>
        <bizfly-button @click="closeDrawer">Xong</bizfly-button>
      </vue-draggable-resizable>
      <div
        v-show="showGuide"
        class="draw-guide"
        @click="showFirstGuide = false"
      >Click chọn hoặc kéo chuột để khoanh vùng nổi bật hoặc chọn bôi đen để ẩn các thông tin nhạy cảm</div>
    </div>
  </div>
</template>

<script>
import BizflyButton from '../Button/index'
import BizflyInput from '../Input/index'
import BizflyCheckbox from '../Checkbox/index'
import BizflyForm from '../Form/index'
import BizflyFormItem from '../FormItem/index'
import BizflyDropdown from '../Dropdown/index'
import BizflyDropdownMenu from '../DropdownMenu/index'
import BizflyDropdownItem from '../DropdownItem/index'
import BizflyIcon from '../Icon/index'
import { RadioGroup, RadioButton } from 'element-ui'
import html2canvas from 'html2canvas'
import VueDraggableResizable from 'vue-draggable-resizable'
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'BizflyFeedback',
  components: {
    BizflyIcon,
    BizflyButton,
    BizflyCheckbox,
    BizflyFormItem,
    BizflyInput,
    BizflyForm,
    BizflyDropdown,
    BizflyDropdownMenu,
    BizflyDropdownItem,
    VueDraggableResizable,
    BizflyRadioGroup: RadioGroup,
    BizflyRadioButton: RadioButton
  },
  props: {
    user: Object,
    top: {
      type: [String, Number],
      default: 'unset'
    },
    left: {
      type: [String, Number],
      default: 'unset'
    },
    right: {
      type: [String, Number],
      default: 'unset'
    },
    bottom: {
      type: [String, Number],
      default: 'unset'
    },
    onLeft: {
      type: Boolean,
      default: true
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    environment: {
      type: String,
      default: 'manage'
    }
  },
  data() {
    return {
      feedbackForm: {
        message: ''
      },
      highlightedArea: null,
      helpersContainer: null,
      canvas: null,
      ctx: null,
      screenshotCanvas: null,
      defaultOptions: {
        backgroundOpacity: 0.5,
        allowedTags: [
          'button', 'a', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'p',
          'i', 'strong', 'small', 'sub', 'sup', 'b', 'time', 'img',
          'caption', 'input', 'label', 'legend', 'select', 'textarea',
          'details', 'summary'
        ],
        endpoint: {
          feedback: 'https://manage.bizflycloud.vn/ticket/send',
          image: 'https://parse.paas.vn/parse/files/'
        }
      },
      html2canvasOptions: {
        allowTaint: true,
        useCORS: true,
        removeContainer: true,
        // foreignObjectRendering caused bug when scrolling
        foreignObjectRendering: false // Fixes bug inaccurate position (with vue render?), but supported in modern browser only
      },
      initState: {
        isOpen: false,
        isDragging: false,
        dragged: false,
        canDraw: false,
        includeScreenshot: false,
        highlight: true,
        isDrawing: false,
        sending: false
      },
      initArea: {
        startX: 0,
        startY: 0,
        width: 0,
        height: 0
      },
      state: {
        isOpen: false,
        isDragging: false,
        dragged: false,
        canDraw: false,
        includeScreenshot: false,
        highlight: true,
        isDrawing: false,
        sending: false
      },
      area: {
        startX: 0,
        startY: 0,
        width: 0,
        height: 0
      },
      helperElements: [],
      helpers: [],
      helperIdx: 0,
      drawOptionsPos: {
        startX: 0,
        startY: 0,
        currTransform: null,
        nextTransform: null,
        limits: {
          xNeg: 0,
          xPos: 0,
          yNeg: 0,
          yPos: 0
        }
      },
      showFeedback: false,
      loadingScreenshot: false,
      showFirstGuide: false
    }
  },
  computed: {
    buttonPositionStyles() {
      return {
        top: this.top,
        left: this.left,
        bottom: this.bottom,
        right: this.right
      }
    },
    showGuide() {
      return this.helpers.length === 0 && this.showFirstGuide
    },
    tokenEndpoint() {
      return `https://manage.bizflycloud.vn/account/api/ticket/token`
    },
    uploadFileEndpoint() {
      return 'https://mps.mediacdn.vn'
    },
    imageDomain() {
      return 'https://ticket-screenshot.mediacdn.vn'
    }
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.open()
        }
      }
    },
    'state.includeScreenshot': {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadingScreenshot = true
          setTimeout(() => {
            this.genScreenshot()
          }, 500)
        }
      }
    }
  },
  methods: {
    goToSupportCenter() {
      this.$emit('go-to-support-center')
      window.open('https://docs.bizflycloud.vn/', '_blank')
    },
    close() {
      this.$emit('close')
      document.removeEventListener('mousemove', this.dragDrag)
      document.removeEventListener('mouseup', this.dragStop)
      document.removeEventListener('mouseup', this.drawStop)
      document.removeEventListener('mousemove', this.drawDraw)
      document.removeEventListener('keydown', this.closeListener)
      document.removeEventListener('mousemove', this.highlightElement)
      document.removeEventListener('click', this.addHighlightedElement)
      window.removeEventListener('resize', this.resize)
      // TODO: Should we remove the inner listeners on close?
      // https://stackoverflow.com/a/37096563/1994803
      this.reset()
    },
    closeListener($event) {
      if ($event.key === 'Escape') {
        this.close()
      }
    },
    openDrawer() {
      this.showFirstGuide = true
      this.state.canDraw = true
      this.drawOptionsPos.currTransform = 'translate(-50%, -50%)'
      document.addEventListener('mousemove', this.highlightElement)
      document.addEventListener('click', this.addHighlightedElement)
    },
    closeDrawer() {
      this.state.canDraw = false
      this.showFirstGuide = false
      this.canvas.classList.remove('active')
      document.removeEventListener('mousemove', this.highlightElement)
      document.removeEventListener('click', this.addHighlightedElement)
      this.loadingScreenshot = true
      setTimeout(() => {
        this.genScreenshot()
      }, 500)
    },
    resize() {
      const width = document.documentElement.scrollWidth
      const height = document.documentElement.scrollHeight
      this.canvas.width = width
      this.canvas.height = height
      this.helpersContainer.style.width = `${width}px`
      this.helpersContainer.style.height = `${height}px`
      this.redraw()
    },
    drawStart($event) {
      if (this.state.canDraw) {
        this.state.isDrawing = true
        this.area = {
          startX: $event.clientX + document.documentElement.scrollLeft,
          startY: $event.clientY + document.documentElement.scrollTop,
          width: 0,
          height: 0
        }
      }
    },
    drawStop($event) {
      if (this.state.canDraw) {
        this.state.isDrawing = false
        if (Math.abs(this.area.width) < 6 || Math.abs(this.area.height) < 6) {
          return
        }
        const helper = Object.assign(Object.assign({}, this.area), {
          highlight: this.state.highlight,
          index: this.helperIdx++
        })
        if (helper.width < 0) {
          helper.startX += helper.width
          helper.width *= -1
        }
        if (helper.height < 0) {
          helper.startY += helper.height
          helper.height *= -1
        }
        this.area = Object.assign({}, this.initArea)
        this.helperElements.push(this.createHelper(helper))
        this.helpers.push(helper)
        this.redraw()
      }
    },
    drawDraw($event) {
      $event.preventDefault()
      if (this.state.isDrawing) {
        this.area.width = $event.clientX - this.area.startX + document.documentElement.scrollLeft
        this.area.height = $event.clientY - this.area.startY + document.documentElement.scrollTop
        // TODO: constant '4' should be lineWidth - also should be optional
        if (this.area.startX + this.area.width > document.documentElement.scrollWidth) {
          this.area.width = document.documentElement.scrollWidth - this.area.startX - 4
        }
        if (this.area.startX + this.area.width < 0) {
          this.area.width = -this.area.startX + 4
        }
        if (this.area.startY + this.area.height > document.documentElement.scrollHeight) {
          this.area.height = document.documentElement.scrollHeight - this.area.startY - 4
        }
        if (this.area.startY + this.area.height < 0) {
          this.area.height = -this.area.startY + 4
        }
        this.resetCanvas()
        this.drawHighlightLines()
        if (this.state.highlight && Math.abs(this.area.width) > 6 && Math.abs(this.area.height) > 6) {
          this.drawLines(this.area.startX, this.area.startY, this.area.width, this.area.height)
          this.ctx.clearRect(this.area.startX, this.area.startY, this.area.width, this.area.height)
        }
        this.paintArea()
        this.paintArea(false)
        if (!this.state.highlight && Math.abs(this.area.width) > 6 && Math.abs(this.area.height) > 6) {
          this.ctx.fillStyle = 'rgba(0,0,0,.5)'
          this.ctx.fillRect(this.area.startX, this.area.startY, this.area.width, this.area.height)
        }
      }
    },
    highlightElement($event) {
      this.highlightedArea = null
      // We need the 3rd element in the list.
      if (!this.state.canDraw || this.state.isDrawing) {
        return
      }
      const el = document.elementsFromPoint($event.x, $event.y)[3]
      if (el) {
        if (this.defaultOptions.allowedTags.indexOf(el.nodeName.toLowerCase()) === -1) {
          this.redraw()
          this.canvas.style.cursor = 'crosshair'
          return
        }
        this.canvas.style.cursor = 'pointer'
        const rect = el.getBoundingClientRect()
        this.highlightedArea = {
          startX: rect.left + document.documentElement.scrollLeft,
          startY: rect.top + document.documentElement.scrollTop,
          width: rect.width,
          height: rect.height
        }
        this.redraw()
        if (this.state.highlight) {
          this.drawLines(this.highlightedArea.startX, this.highlightedArea.startY, this.highlightedArea.width, this.highlightedArea.height)
          this.ctx.clearRect(this.highlightedArea.startX, this.highlightedArea.startY, this.highlightedArea.width, this.highlightedArea.height)
        }
        this.paintArea()
        if (!this.state.highlight) {
          this.ctx.fillStyle = 'rgba(0,0,0,.5)'
          this.ctx.fillRect(this.highlightedArea.startX, this.highlightedArea.startY, this.highlightedArea.width, this.highlightedArea.height)
        }
        this.paintArea(false)
      }
    },
    addHighlightedElement($event) {
      if (this.highlightedArea) {
        if (Math.abs(this.highlightedArea.width) < 6 || Math.abs(this.highlightedArea.height) < 6) {
          return
        }
        const helper = Object.assign(Object.assign({}, this.highlightedArea), {
          highlight: this.state.highlight,
          index: this.helperIdx++
        })
        if (helper.width < 0) {
          helper.startX += helper.width
          helper.width *= -1
        }
        if (helper.height < 0) {
          helper.startY += helper.height
          helper.height *= -1
        }
        this.helperElements.push(this.createHelper(helper))
        this.helpers.push(helper)
      }
    },
    onScroll() {
      // const x = -document.documentElement.scrollLeft
      // const y = -document.documentElement.scrollTop
      // this.canvas.style.left = `${x}px`
      // this.canvas.style.top = `${y}px`
      // this.helpersContainer.style.left = `${x}px`
      // this.helpersContainer.style.top = `${y}px`
    },
    open() {
      if (!this.state.isOpen) {
        this.$emit('open')
        this.state.isOpen = true
        const _this = this
        this.$nextTick(_ => {
          _this.createHelpersContainer()
          _this.createCanvas()
          _this.onScroll()
          document.addEventListener('keydown', _this.closeListener)
          window.addEventListener('scroll', _this.onScroll)
          _this.genScreenshot()
        })
      }
    },
    reset() {
      this.$refs.feedbackForm.resetFields()
      this.state = Object.assign({}, this.initState)
      this.helpers = []
      this.helperElements = []
      this.helperIdx = 0
    },
    send() {
      this.$refs.feedbackForm.validate(valid => {
        if (valid) {
          this.state.sending = true
          if (this.state.includeScreenshot) {
            this.$emit('upload-image')
            const _this = this
            this.screenshotCanvas.toBlob(function(blob) {
              const imgName = new Date().getTime() + '.png'
              const headers = new Headers()
              fetch(`${_this.tokenEndpoint}?filename=${imgName}`, { methods: 'GET', headers: headers, credentials: 'include' })
                .then((response) => response.json())
                .then((data) => {
                  const formData = new FormData()
                  var fileOfBlob = new File([blob], imgName)
                  formData.append('filedata', fileOfBlob)
                  fetch(`${_this.uploadFileEndpoint}/_/upload`, {
                    method: 'POST',
                    headers: {
                      'X-Policy': data.encoded_policy,
                      'X-Signature': data.signature
                    },
                    body: formData
                  }).then((response) => response.json())
                    .then((data) => {
                      const message = `${_this.feedbackForm.message} \n Xem ảnh: ${_this.imageDomain}${data.file_path}`
                      _this.sendFeedback(message)
                    }).catch(_ => {
                      this.$emit('upload-image-failed')
                      _this.state.sending = false
                      _this.$notify({
                        showClose: true,
                        title: 'Thất bại',
                        message: 'Upload ảnh thất bại, vui lòng thử lại sau',
                        type: 'error',
                        customClass: 'error'
                      })
                    })
                })
            })
          } else {
            this.sendFeedback(this.feedbackForm.message)
          }
        }
      })
    },
    sendFeedback(message) {
      this.state.sending = true
      const currentUrl = `Tại trang: ${window.location.href.split('/iframe.html?').join('/?')}`
      const data = new FormData()
      data.append('FullName', this.user.fullname || this.user.name)
      data.append('Email', this.user.email)
      data.append('Content', decodeURI(`${message} ${currentUrl}`))
      fetch(this.defaultOptions.endpoint.feedback, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        body: data
      }).then(function(response) {
        if (response.type === 'opaque') {
          return response
        }
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      }).then((data) => {
        this.$emit('send-feedback-successful')
        this.$notify({
          showClose: true,
          title: 'Thành công',
          message: 'Cảm ơn quý khách đã gửi phản hồi!',
          type: 'success',
          customClass: 'success'
        })
        this.state.sending = false
        this.close()
      }).catch(() => {
        this.$emit('send-feedback-failed')
        this.state.sending = false
        this.$notify({
          showClose: true,
          title: 'Thất bại',
          message: 'Gửi phản hồi thất bại, vui lòng thử lại sau',
          type: 'error',
          customClass: 'error'
        })
      })
    },
    genScreenshot() {
      if (this.state.includeScreenshot) {
        const _this = this
        this.html2canvasOptions = Object.assign(Object.assign({}, this.html2canvasOptions), {
          // width: window.innerWidth,
          // height: window.innerHeight,
          // scrollX: window.pageXOffset,
          // scrollY: window.pageYOffset,
          // x: window.pageXOffset,
          // y: window.pageYOffset,
          width: window.innerWidth,
          height: window.innerHeight,
          scrollX: window.pageXOffset,
          scrollY: window.pageYOffset,
          x: window.pageXOffset,
          y: window.pageYOffset,
          ignoreElements: function(element) {
            // Remove mask if there is no helpers(highlight/blackout) and ignore draw guide
            return !_this.helpers.length && element.classList.contains('draw-area') || element.classList.contains('draw-guide') || element.classList.contains('trigger-button')
          }
        })
        while (this.$refs.screenshotContainer.firstChild) {
          this.$refs.screenshotContainer.removeChild(this.$refs.screenshotContainer.firstChild)
        }
        this.redraw(false)
        html2canvas(document.body, this.html2canvasOptions)
          .then((canvas) => {
            this.screenshotCanvas = canvas
            this.$refs.screenshotContainer.appendChild(canvas)
            this.redraw()
            this.loadingScreenshot = false
          })
      }
    },
    createCanvas() {
      const canvas = document.createElement('canvas')
      canvas.width = document.documentElement.scrollWidth
      canvas.height = document.documentElement.scrollHeight
      canvas.className = 'draw-area'
      canvas.addEventListener('mousedown', this.drawStart)
      document.addEventListener('mouseup', this.drawStop)
      document.addEventListener('mousemove', this.drawDraw)
      window.addEventListener('resize', this.resize)
      this.canvas = canvas
      this.ctx = canvas.getContext('2d')
      this.resetCanvas()

      this.$refs.feedbackContainer.appendChild(canvas)
    },
    createHelpersContainer() {
      const helpersContainer = document.createElement('div')
      helpersContainer.className = 'helpers'
      helpersContainer.style.width = `${document.documentElement.scrollWidth}px`
      helpersContainer.style.height = `${document.documentElement.scrollHeight}px`
      this.helpersContainer = helpersContainer

      this.$refs.feedbackContainer.appendChild(helpersContainer)
    },
    resetCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.ctx.fillStyle = 'rgba(102,102,102,.5)'
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    },
    drawHighlightLines() {
      this.helpers.filter(helper => helper.highlight)
        .forEach(helper => {
          this.drawLines(helper.startX, helper.startY, helper.width, helper.height)
        })
    },
    paintArea(highlight = true) {
      if (highlight) {
        this.helpers.filter(helper => helper.highlight)
          .forEach(helper => {
            this.ctx.clearRect(helper.startX, helper.startY, helper.width, helper.height)
          })
      } else {
        this.helpers.filter(helper => !helper.highlight)
          .forEach(helper => {
            this.ctx.fillStyle = 'rgba(0,0,0,1)'
            this.ctx.fillRect(helper.startX, helper.startY, helper.width, helper.height)
          })
      }
    },
    redraw(withBorder = true) {
      this.resetCanvas()
      if (withBorder) {
        this.drawHighlightLines()
      }
      this.paintArea()
      this.paintArea(false)
    },
    drawLines(x, y, width, height) {
      this.ctx.strokeStyle = '#ffeb3b'
      this.ctx.lineJoin = 'bevel'
      this.ctx.lineWidth = 4
      this.ctx.strokeRect(x, y, width, height)
      this.ctx.lineWidth = 1
    },
    createHelper(helper) {
      const h = document.createElement('div')
      h.className = helper.highlight ? 'highlight' : 'blackout'
      h.style.position = 'absolute'
      h.style.top = `${helper.startY}px`
      h.style.left = `${helper.startX}px`
      h.style.height = `${helper.height}px`
      h.style.width = `${helper.width}px`
      h.style.zIndex = '20'
      h.setAttribute('idx', `${helper.index}`)
      const inner = document.createElement('div')
      inner.style.width = `${helper.width - 2}px`
      inner.style.height = `${helper.height - 2}px`
      inner.style.margin = '1px'
      const removeButton = document.createElement('button')
      removeButton.innerText = 'Xóa'
      removeButton.classList.add('remove-highlight-blackout')
      removeButton.style.position = 'absolute'
      removeButton.style.right = '0'
      removeButton.style.top = '0'
      removeButton.addEventListener('click', ($event) => {
        removeButton.parentNode.parentNode.removeChild(h)
        this.helpers.splice(this.helpers.findIndex(_helper => _helper.index === helper.index), 1)
        this.helperElements.splice(this.helperElements.findIndex(_helper => +_helper.getAttribute('idx') === helper.index), 1)
        this.redraw()
      })
      h.addEventListener('mouseenter', ($event) => {
        if (this.state.canDraw && !this.state.isDrawing) {
          h.appendChild(inner)
          h.appendChild(removeButton)
          if (!helper.highlight) {
            this.resetCanvas()
            this.drawHighlightLines()
            this.paintArea()
            this.ctx.clearRect(helper.startX, helper.startY, helper.width, helper.height)
            this.ctx.fillStyle = 'rgba(0,0,0,.75)'
            this.ctx.fillRect(helper.startX, helper.startY, helper.width, helper.height)
            this.helpers.filter(_helper => !_helper.highlight && _helper.index !== helper.index)
              .forEach(_helper => {
                this.ctx.fillStyle = 'rgba(0,0,0,1)'
                this.ctx.fillRect(_helper.startX, _helper.startY, _helper.width, _helper.height)
              })
          }
        }
      })
      h.addEventListener('mouseleave', ($event) => {
        if (this.state.canDraw && !this.state.isDrawing && h.hasChildNodes()) {
          h.removeChild(inner)
          h.removeChild(removeButton)
          if (!helper.highlight) {
            this.redraw()
          }
        }
      })
      this.helpersContainer.appendChild(h)
      return h
    }
  }
}
</script>
