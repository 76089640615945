<template>
  <div class="bizfly-ui bizfly-container" :class="{ 'fluid': fluid }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BizflyContainer',
  props: {
    fluid: {
      type: Boolean,
      default: false
    }
  }
}
</script>
