<script>
import { Popover } from 'element-ui'

export default {
  name: 'BizflyPopover',
  extends: Popover,
  props: {
    customClass: {
      type: String,
      default: 'bizfly-ui bizfly-popover'
    }
  }
}
</script>
