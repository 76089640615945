// eslint-disable-next-line import/prefer-default-export
async function getUserInfo(currentEnvironment = 'manage') {
  const response = await fetch(`https://${currentEnvironment}.bizflycloud.vn/account/api/users/info?only=last_region,tenant_id`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (response.status === 401) {
    window.location.href = `https://${currentEnvironment}.bizflycloud.vn/cas/logout?service=${window.location.href}`
    return
  }

  return response.json()
}

export default {
  getUserInfo
}
